import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SecureOutlet } from './components/SecureOutlet';
import ErrorPage from './components/ErrorPage';
import Login from './features/login/Login';
import routeData from './common/route-data';
import getAppRoutes from './common/AppRoutes';


// wrap app rendering so that we can wait for any async init to complete
async function start() {


  const router = createBrowserRouter([
    {
      path: "/",
      element: <SecureOutlet />,
      errorElement: <ErrorPage />,
      children: getAppRoutes(routeData),
    },
    {
      path: '/login',
      element: <Login />,
      errorElement: <ErrorPage />
    }
  ]);



  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
        {/* <App /> */}
      </Provider>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

}

start();
