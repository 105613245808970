import { PropsWithChildren, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../app/store";

export interface IRequireAuthProps extends PropsWithChildren {}

const RequireAuth = ({children}: IRequireAuthProps) => {

  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);

  useEffect(() => {
    if (accessToken === null) {
      console.warn('UserToken is null. Have to login.');
      navigate('/login',{replace: true, state: {path: location.pathname}});
    }
  }, [accessToken, navigate, location.pathname]);

  return (<>{children}</>);

  // return accessToken ? children : <Navigate to="/login" replace />;
  // return accessToken ? children : {navigate()};
}

export default RequireAuth;