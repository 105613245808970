import { useCallback, useEffect, useState } from "react";
import { IStudentInfoResponse } from "../../features/student/models/IStudent";
import { useLazyGetStudentByAdmissionNumberQuery } from "../../features/student/services/studentSlice";
import { MAX_STUDENT_ADMISSION_NUMBER, MIN_STUDENT_ADMISSION_NUMBER } from "../../constants/app-constants";
// import styles from './StudentIdCard.module.scss';

export interface IStudentIdCardProps {
  admissionNumber?: number;
}


// const CheckedIcon = () => <>🌜</>;
// const UncheckedIcon = () => <>🌞</>;

// https://github.com/webomnizz/react-toggle-button

// TODO: this is still geared towards student. Make this generic to pull up any cardText.
const IdCardText = ({ admissionNumber }: IStudentIdCardProps) => {
  const [getStudentByAdmNumTrigger, {isFetching: studentIsFetching, isError: studentIsError}] = useLazyGetStudentByAdmissionNumberQuery();
  const [student, setStudent] = useState<IStudentInfoResponse>();

  const getStudent = useCallback(async (admNum: number) => {
    if (admNum > MIN_STUDENT_ADMISSION_NUMBER && admNum < MAX_STUDENT_ADMISSION_NUMBER) {
      // get the student from database by admission number
      try {
            console.log('getStudent', admNum);
            const result = await getStudentByAdmNumTrigger(admNum).unwrap();
            console.log('fulfilled', result, admNum, Number(result.admissionNumber));
            if (Number(result.admissionNumber) === admNum) {
                setStudent(result);
                console.log('setStudent', result);
            } else {
              console.log('setStudent to undefined');
              setStudent(undefined);
            }
        } catch (error) {
            console.error('rejected', error);
            setStudent(undefined);
        }
    } else {
        console.log('At this time, cannot search for Staff IDs. Will not search for number ', admNum);
    }
  },[getStudentByAdmNumTrigger]);

  // const getName = (s: IStudentInfoResponse) => {
  //   if (student?.middleName) {
  //     let t = student?.title.endsWith('.') ? student.title : `${student.title}.`;
  //     var n = `${t} ${student?.firstName} ${student?.middleName} ${student?.lastName}`;
  //   } else {
  //     n =`${student?.title} ${student?.firstName} ${student?.lastName}`;
  //   }
  //   return n;
  // }

  useEffect(() => {
    if (admissionNumber !== undefined)
      getStudent(admissionNumber);
    else setStudent(undefined);
  }, [admissionNumber, getStudent]);

  return (
    // <div style={{ backgroundImage:`url(${IMAGE_CARD_EMPTY_TEMPLATE})`,backgroundRepeat:"no-repeat", backgroundSize: 'contain', height: '400px' }}>
    //   Hello World
    // </div>

    <>
      {studentIsFetching ? <>Fetching...<br/></> : null}
      {studentIsError ? <>An error occurred!</>: null}

      {!studentIsFetching && !studentIsError && student === undefined ? <>Not Found!</> :
        <>
          {/* <i>Student ID Card image is missing.</i> */}
          <div className="col form-floating1 m-3">
          <label >Admission Number</label>
          <label className="form-control" id="floatingInput">{student?.admissionNumber === undefined ? "" : student?.admissionNumber}</label>
        </div>
          <div className="col form-floating1 m-3">
            <label >Name</label>
            <label className="form-control" id="floatingInput">{student?.title}. {student?.fullName}</label>
          </div>
          <div className="col form-floating1 m-3">
            <label >Class</label>
            <label className="form-control" id="floatingInput">{student?.className}</label>
          </div>
          <div className="col form-floating1 m-3">
            <label >Contact</label>
            <label className="form-control" id="floatingInput">{student?.fatherMobileNumber}</label>
          </div></>
      }
    </>


  )
}

export default IdCardText;