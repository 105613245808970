export interface IRouteDataItem {
  routeName: string;
  icon: string;
  title: string;
  isHeading?: boolean;
  subRoutes?: IRouteDataItem[];
}


export const RouteNameConstants = {
  home: 'home',
  student: 'student',
  studentall: 'studentall',
  cardservices: 'cardservices',
  registerstudentcard: 'registerstudentcard',
  registerstaffcard: 'registerstaffcard',
  verifyidcard: 'verifyidcard',
  cardinfo: 'cardinfo',
  transportservices: 'transportservices',
  transportdashboard: 'transportdashboard',
  studentbusroute: 'studentbusroute',
  routemanagement: 'routemanagement',
  landmarkmanagement: 'landmarkmanagement',
  studentstatus: 'studentstatus',
  studentexceptions: 'studentexceptions',
  staff: 'staff',
  staffall: 'staffall',
  reports: 'reports',
}


const routeData: IRouteDataItem[] = [
  { icon: '1', routeName: RouteNameConstants.home, title: 'Home' },
  {
    icon: '2', routeName: RouteNameConstants.cardservices, title: 'Card Services', isHeading: true,
    subRoutes: [
      { icon: '2.1', routeName: RouteNameConstants.registerstudentcard, title: 'Register Student Card' },
      { icon: '2.2', routeName: RouteNameConstants.registerstaffcard, title: 'Register Staff Card' },
      { icon: '2.3', routeName: RouteNameConstants.verifyidcard, title: 'Verify ID Card' },
      { icon: '2.4', routeName: RouteNameConstants.cardinfo, title: 'Card Info' },
    ]
  },
  {
    icon: '3', routeName: RouteNameConstants.transportservices, title: 'Transport Services', isHeading: true,
    subRoutes: [
      { icon: '3.1', routeName: RouteNameConstants.transportdashboard, title: 'Transport Dashboard' },
      { icon: '3.2', routeName: RouteNameConstants.studentbusroute, title: 'Student Bus Routes' },
      { icon: '3.3', routeName: RouteNameConstants.routemanagement, title: 'Bus Route Management' },
      { icon: '3.4', routeName: RouteNameConstants.landmarkmanagement, title: 'Landmark Management' },
    ]
  },

  { icon: '8', routeName: RouteNameConstants.studentstatus, title: 'Student Status' },
  { icon: '9', routeName: RouteNameConstants.studentexceptions, title: 'Student Exceptions' },
  // { icon: '10', routeName: 'busservices', title: 'Bus Services' },
  {
    icon: '10', routeName: RouteNameConstants.transportservices, title: 'Student Management', isHeading: true,
    subRoutes: [
      { icon: '10.1', routeName: RouteNameConstants.student, title: 'Student Info' },
      { icon: '10.2', routeName: RouteNameConstants.studentall, title: 'All Students' },
    ]
  },
  //{ icon: '10', routeName: RouteNameConstants.student, title: 'New Student' },
  // { icon: '12', routeName: 'login', title: 'Login' },
  {
    icon: '11', routeName: RouteNameConstants.transportservices, title: 'Staff Management', isHeading: true,
    subRoutes: [
      { icon: '11.1', routeName: RouteNameConstants.staff, title: 'New Staff' },
      { icon: '11.2', routeName: RouteNameConstants.staffall, title: 'All Staff' },
    ]
  },
  { icon: '12', routeName: RouteNameConstants.reports, title: 'Reports' },

];




export default routeData;