import { Select } from '@chakra-ui/react';
import React, { ReactNode, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Gender, IStaffInfoRequest, StaffType } from './models/IStaff';
import { useCreateNewStaffMutation } from './services/staffSlice';

const Staff = () => {
    const [staff, setStaff] = useState<IStaffInfoRequest>(initStaff);
    const [createStaffTrigger] = useCreateNewStaffMutation();
    const [msg, setMsg] = useState<string>('');
    

    function initStaff(): IStaffInfoRequest {
        return {
            classesHandled: '',
            dateOfBirth: '',
            designation: '',
            emailAddress: '',
            firstName: '',
            fullName: '',
            gender: Gender.Male,
            id: '',
            joiningYear: new Date().getFullYear(),
            lastName: '',
            middleName: '',
            mobileNumber: '',
            qualification: '',
            staffId: 0,
            title: '',
            type: StaffType.DayTeacher,
            isActive: true,
        }
    }

    function getEnumEntries(myenum: typeof StaffType | typeof Gender): ReactNode[] {
        let retval: ReactNode[] = [];
        for (const [key, value] of Object.entries(myenum)) {
            console.log(`${key}, value: ${value}`);
            if (key.length > 1) {
                retval.push(<option value={`${key}`} key={`${key}`}>{key}</option>);
            }
        }
        return retval;
    }

    const createStaff = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        console.log('Creating staff', staff);
        
        try {
            let res = await createStaffTrigger(staff).unwrap();
            console.log('success', res);
            setMsg('Success adding Staff member');
        } catch (error: any) {
            console.error('error', error);
            setMsg(error.data);
        }
    }

    const clearForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        console.log('Clearform ');
        setStaff(initStaff);
    }


    const handleChange = (property: keyof IStaffInfoRequest, value: string) => {
        // setStaff(prev => 
        //     ({ ...prev, [event.target.name]: [event.target.value] }));
        setStaff({ ...staff, [property]: value });
    };


    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: "20px",
                }}
            >
                <Card style={{ textAlign: "left", width: "600px" }}>
                        <Card.Header>Create New Staff Person</Card.Header>
                        <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">
                            <h1 className="h3 mb-3 fw-normal">{msg}</h1>
                        </Card.Subtitle>
                        <main className="form w-100 m-auto">
                            <div className='container'>
                                <form className='row'>
                                    <div className="col form-floating1 m-3">
                                        <label >Staff ID</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="YYYYxxxx format"
                                            value={staff.staffId === 0 ? '' : staff.staffId} onChange={(e) => handleChange('staffId', e.target.value)} />
                                    </div>
                                    <div className="col form-floating1 m-3">
                                        <label >Title</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Mr, Mrs, Ms etc"
                                            value={staff.title} onChange={(e) => handleChange('title', e.target.value)} />
                                    </div>
                                    <div className="form-floating1 m-3">
                                        <label >First Name *</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Enter First Name"
                                            value={staff.firstName} onChange={(e) => handleChange('firstName', e.target.value)} />
                                    </div>
                                    <div className="form-floating1 m-3">
                                        <label >Middle Name</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Enter Middle Name"
                                            value={staff.middleName} onChange={(e) => handleChange('middleName', e.target.value)} />
                                    </div>
                                    <div className="form-floating1 m-3">
                                        <label >Last Name *</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Enter Surname" 
                                            value={staff.lastName} onChange={(e) => handleChange('lastName', e.target.value)} />
                                    </div>
                                    <div className="col form-floating1 m-3">
                                        <label >Classes Handled</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Comma separated classes (6,7,8)" 
                                            value={staff.classesHandled} onChange={(e) => handleChange('classesHandled', e.target.value)} />
                                    </div>
                                    <div className="col form-floating1 m-3">
                                        <label >Qualification</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="M.A, B.Ed etc"
                                            value={staff.qualification} onChange={(e) => handleChange('qualification', e.target.value)} />
                                    </div>         
                                    <div className="form-floating1 m-3">
                                        <label >Email Address</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Personal email (or School email)" 
                                            value={staff.emailAddress} onChange={(e) => handleChange('emailAddress', e.target.value)}/>
                                    </div>                           
                                    <div className="col form-floating1 m-3">
                                        <label >Gender *</label>
                                        <Select placeholder='Select' value={staff.gender} onChange={(e) => handleChange('gender', e.target.value)}>
                                            {getEnumEntries(Gender)}
                                        </Select>
                                    </div>
                                    <div className="col form-floating1 m-3">
                                        <label >Date Of Birth</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="DD/MM/YYYY"
                                            value={staff.dateOfBirth} onChange={(e) => handleChange('dateOfBirth', e.target.value)} />
                                    </div>
                                    <div className='row'>&nbsp;</div>

                                    <div className="col form-floating1 m-3">
                                        <label >Mobile Number</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Important! Enter Mobile Number"
                                            value={staff.mobileNumber} onChange={(e) => handleChange('mobileNumber', e.target.value)} />
                                    </div>
                                    <div className="col form-floating1 m-3">
                                        <label >Designation</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="TEACHER, OFFICE ASSISTANT etc"
                                            value={staff.designation} onChange={(e) => handleChange('designation', e.target.value)} />
                                    </div>
                                    <div className='row'>&nbsp;</div>

                                    <div className="col form-floating1 m-3">
                                        <label >Join&nbsp;Year</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Year of joining (YYYY)" 
                                            value={staff.joiningYear === 0 ? '' : staff.joiningYear} onChange={(e) => handleChange('joiningYear', e.target.value)}/>
                                    </div>

                                    <div className="col form-floating1 m-3">
                                        <label >Staff Type</label>
                                        <Select>
                                            {getEnumEntries(StaffType)}
                                        </Select>
                                        {/* <input type="text" className="form-control" id="floatingInput" placeholder="Year of joining (YYYY)" 
                                            value={staff.type} onChange={(e) => handleChange('type', e.target.value)}/> */}
                                    </div>
                                    <div className="m-auto">
                                        <button className="w-25 m-3 btn btn-md btn-primary" onClick={(e) => createStaff(e)} >Create</button>
                                        <button className="w-25 m-3 btn btn-md btn-danger" onClick={(e) => clearForm(e)}>Cancel</button>
                                    </div>
                                    <div><h1 className="h3 mb-3 fw-normal">{msg}</h1></div>
                                </form>
                            </div>
                        </main>
                    </Card.Body>
                    <Card.Footer>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                            }}
                        >
                        </div>
                    </Card.Footer>
                </Card>

            </div>
        </>
    )

}


export default Staff;