import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ToggleButton from "../ToggleButton/ToggleButton";
import styles from './AppDatePicker.module.scss';

export interface IAppDatePickerProps {
  onToggled?: (toggleState: boolean) => void;
  onDateSelected?: (dt: Date) => void;
}

const AppDatePicker = ({ onToggled, onDateSelected }: IAppDatePickerProps) => {

  const [startDate, setStartDate] = useState<Date>(new Date());

  const dateSelected = (dt: Date | null) => {
    if (dt) {
      setStartDate(dt);
      onDateSelected && onDateSelected(dt)
    }
  }

  const toggled = (toggleState: boolean) => {
    console.log(toggleState);
    if (onToggled) onToggled(toggleState);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', justifyContent: 'flex-start' }}>
      <ToggleButton trueText="Pick up" falseText="Drop off" defaultChecked={true} onChange={toggled} disabled={false} textWidth={100} />
      {/* <ToggleButton trueText="Own&nbsp;Transport" falseText="School Transport" defaultChecked={true} onChange={toggled} disabled={false} textWidth={160} /> */}
      <div>
        <DatePicker
          selected={startDate}
          onChange={(date) => dateSelected(date)}
          dateFormat="dd MMM yyyy"
          className={styles.datePicker}
        />
      </div>
    </div>
  )
}

export default AppDatePicker;