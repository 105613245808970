
export interface ITimelineIconProps {
  color?: string;
  numberText: number;
}

const TimelineIcon = ({ color, numberText }: ITimelineIconProps) => {
  return (
    <span style={{ fontSize: '26px', backgroundColor: '' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="55" height="60" strokeWidth="0.1" fill={color ?? 'white'} strokeLinecap="round" strokeLinejoin="round">
        <text textAnchor='middle' x="12" y="23" width='1em' height='1em'>{numberText}</text>
      </svg>
    </span>
  )
}

export default TimelineIcon;