import { RouteObject } from "react-router-dom";
import Placeholder from "../components/Placeholder";
import { IRouteDataItem, RouteNameConstants } from "./route-data";
import BusRoutes from "../features/busRoutes/BusRoutes";
import { IdCardType } from "../features/idcard/models/IIdCard";
import RegisterIdCard from "../features/idcard/RegisterIdCard";
import VerifyIdCard from "../features/idcard/VerifyIdCard";
// import BusMap from "../features/maps/BusMap";
import Student from "../features/student/Student";
import RouteManagement from "../components/RouteManagement/RouteManagement";
import RequireAuth from "../components/RequireAuth";
import Staff from "../features/staff/Staff";
import AllStaff from "../features/staff/AllStaff";
import AllReports from "../features/reports/AllReports";
import ManageLandmarksForRoute from "../components/RouteManagement/AddLandmark";
// import AllReports from "../features/reports/AllReports";
// import AllStudent from "../features/student/AllStudent";


const mapRouteToComponent = (routeData: IRouteDataItem): RouteObject | undefined => {
  console.log(`--> ${routeData.routeName}`);
  switch (routeData.routeName) {
    case RouteNameConstants.staff:
      return { path: routeData.routeName, element: <RequireAuth><Staff /></RequireAuth> };
    case RouteNameConstants.reports:
      return { path: routeData.routeName, element: <RequireAuth><AllReports /></RequireAuth> };
    case RouteNameConstants.staffall:
      return { path: routeData.routeName, element: <RequireAuth><AllStaff /></RequireAuth> };
    case RouteNameConstants.student:
      return { path: routeData.routeName, element: <RequireAuth><Student /></RequireAuth> };
    // case RouteNameConstants.studentall:
    //   return { path: routeData.routeName, element: <RequireAuth><AllStudent /></RequireAuth> };
    case RouteNameConstants.studentbusroute:
      return { path: routeData.routeName, element: <RequireAuth><BusRoutes /></RequireAuth> };
    case RouteNameConstants.verifyidcard:
      return { path: routeData.routeName, element: <RequireAuth><VerifyIdCard /></RequireAuth> };
    case RouteNameConstants.registerstudentcard:
      return { path: routeData.routeName, element: <RequireAuth><RegisterIdCard cardType={IdCardType.Student} /></RequireAuth> };
    case RouteNameConstants.registerstaffcard:
      return { path: routeData.routeName, element: <RequireAuth><RegisterIdCard cardType={IdCardType.Staff} /></RequireAuth> };
    case RouteNameConstants.routemanagement:      
      return { path: routeData.routeName, element: <RequireAuth><RouteManagement /></RequireAuth> };
    case RouteNameConstants.landmarkmanagement:
      return { path: routeData.routeName, element: <RequireAuth><ManageLandmarksForRoute /></RequireAuth> };      
    case RouteNameConstants.transportdashboard:      
      // BusMap is rendered from SecureOutlet to minimize renders (which increases Quota count in googlemaps)
      // return { path: routeData.routeName, element: <BusMap /> };
      return undefined;
    default:
      return undefined;
  }
}

const getAppRoutes = (routeData: IRouteDataItem[]): RouteObject[] => {
  let retval: RouteObject[] = [];

  routeData.map((item, index) => {

    if (item.isHeading) {
      item.subRoutes && item.subRoutes.map((subitem, index) => {
        // loop through the sub items
        let smap = mapRouteToComponent(subitem);
        smap ? retval.push(smap) : retval.push({ path: subitem.routeName, element: <RequireAuth><Placeholder text={`${subitem.title}`} /></RequireAuth> });
        return '';
      });
    } else {
      let rmap = mapRouteToComponent(item);
      rmap ? retval.push(rmap) : retval.push({ path: item.routeName, element: <RequireAuth><Placeholder text={`${item.title}`} /></RequireAuth> });
    }
    return '';
  });

  return retval;
}

export default getAppRoutes;