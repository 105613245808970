export enum StaffType {
    DayStaff = 0,
    ResidentStaff ,
    TemporaryStaff,
    DayNonTeachingStaff,
    ResidentNonTeachingStaff,
    DayTeacher,
    ResidentTeacher,
    DayWorker,
    ResidentWorker

}

export enum Gender {
    Male,
    Female
}


interface IStaffInfo {
  id: string,
  staffId: number,
  title: string,
  fullName: string,
  firstName: string,
  middleName: string,
  lastName: string,
  classesHandled: string,
  gender: Gender,
  dateOfBirth: string,
  emailAddress: string,
  mobileNumber: string,
  designation: string,
  qualification: string,
  joiningYear: number,
  isActive: boolean,
  type: StaffType
}

export interface IStaffInfoRequest extends IStaffInfo {
    
}

export interface IStaffInfoResponse extends IStaffInfo {
    
}

