import { Button, Card } from 'react-bootstrap';
import { ILandmark } from "../../features/busRoutes/models/ILandmark";


export interface ILandmarkCardProps {
  landmark: ILandmark;
  showStudents?: boolean;
}

const LandmarkCard = ({ landmark, showStudents }: ILandmarkCardProps) => {

  return (
    <>
      <Card style={{ textAlign: "left", width: "100%" }}>
        <Card.Header style={{fontWeight: '700'}}>{landmark.landmarkName}</Card.Header>
        <Card.Body>
          <div>
            <span>Students: {landmark.studentCount}</span>&nbsp;&nbsp;<Button disabled={landmark.studentCount === 0} onClick={() => alert('work in progress.')}>Send SMS</Button>
          </div>
          <div>
            {showStudents ? 
              <span>show students</span>
            : null}
          </div>
        </Card.Body>
        <Card.Footer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
          </div>
        </Card.Footer>
      </Card>

    </>
  )
}

export default LandmarkCard;