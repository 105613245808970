import { useState, PropsWithChildren } from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setCurrentScreen } from "../app/appSlice";
import { RootState, useAppDispatch } from "../app/store";
import { useAuth } from "../app/useAuth";
import routeData, { IRouteDataItem } from "../common/route-data";

const Theme = {
  Light: "light" as const,
  Dark: "dark" as const
}

interface IMainLayoutProps extends PropsWithChildren { }


const MainLayout = ({ children }: IMainLayoutProps) => {
  const auth = useAuth();
  const currentScreen = useSelector((state: RootState) => state.app.currentScreen);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDarkTheme] = useState<boolean>(false);
  const themeName = isDarkTheme ? Theme.Dark : Theme.Light;

  
  const onSelect = (eventKey: string | null) => {
    if (eventKey) {
      console.log(`EventKey: `, eventKey);
      let eKey = eventKey.split('|');
      dispatch(setCurrentScreen(eKey[1]));
      navigate(`${eKey[0]}`);
    }

  }

  const makeItem = (item: IRouteDataItem) => {
    return (
      <SidebarMenu.Nav.Link key={`${item.routeName}`} eventKey={`${item.routeName}|${item.title}`} className="nav-link">
        <SidebarMenu.Nav.Icon >{item.icon}</SidebarMenu.Nav.Icon>
        <SidebarMenu.Nav.Title>{item.title}</SidebarMenu.Nav.Title>
      </SidebarMenu.Nav.Link>
    );
  }

  const makeSubItem = (item: IRouteDataItem) => {

    if (item.subRoutes !== undefined) {
      return (
        <SidebarMenu.Sub key={`${item.icon}`} eventKey={2}>
          <SidebarMenu.Sub.Toggle>
            <SidebarMenu.Nav.Icon />
            <SidebarMenu.Nav.Title>{item.title}</SidebarMenu.Nav.Title>
          </SidebarMenu.Sub.Toggle>
          <SidebarMenu.Sub.Collapse>
            <SidebarMenu.Nav>
              {item.subRoutes.map((subitem, index) => {
                return makeItem(subitem);
              })}
            </SidebarMenu.Nav>
          </SidebarMenu.Sub.Collapse>
        </SidebarMenu.Sub>
      );
    }
  }

  const getSidebarMenu = (routes: IRouteDataItem[]) => {
    return routes.map((item, index) => {
      if (!item.isHeading) {
        return makeItem(item);
      } else {
        return makeSubItem(item);
      }            
    })
  }

  const logout = () => {
    localStorage.removeItem('auth');
    // navigate(`/login`);
  }



  return (
    <div className="main-wrapper main-wrapper-responsive-lg">
      <Navbar className="main-header"  bg={themeName} variant={themeName}>
        <Navbar.Brand title="VRS Vignana Jyothi Admin Portal" className="d-block">VRS Vignana Jyothi School Admin {`${process.env.REACT_APP_ENV}`} Portal</Navbar.Brand>
        <SidebarMenu.Toggle />
        {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
        <span className={'justify-content-end'} >{currentScreen}</span>
        <Navbar.Collapse className={'justify-content-end'}>          
            {/* <Link to={`busservices`}>Bus Services</Link> */}
            {/* &nbsp; */}
            {/* <Link to={`cardservices`}>Card Services</Link> */}
            <NavDropdown className={'dropdown-left'} title="Settings" id="basic-nav-dropdown">
              <NavDropdown.ItemText>
                {/* <CheckBox id="darkThemeSwitcher" checked={isDarkTheme} onChange={() => setIsDarkTheme(!isDarkTheme)} text={themeName} /> */}
                <div>{auth.user?.userDisplayName}</div>
              </NavDropdown.ItemText>
              <NavDropdown.ItemText>
                <Link to={`login`} onClick={logout}>Logout</Link>
              </NavDropdown.ItemText>
            </NavDropdown>
          
        </Navbar.Collapse>
      </Navbar>

      <SidebarMenu
        onSelect={onSelect}
        variant={themeName}
        bg={themeName}
        expand="lg"
      >
        <SidebarMenu.Collapse>
          <SidebarMenu.Header>
            <SidebarMenu.Brand title="VRSVJSchool">
              <span>&nbsp;</span>
              {/* <span className="react-bootstrap-img" /> */}
              {/* <span>VRS Vignana Jyothi Admin Portal</span> */}
            </SidebarMenu.Brand>
            {/* <SidebarMenu.Toggle /> */}
          </SidebarMenu.Header>
          <SidebarMenu.Body>
            <SidebarMenu.Nav>
              {getSidebarMenu(routeData)}
            </SidebarMenu.Nav>
          </SidebarMenu.Body>
          <SidebarMenu.Footer>
          </SidebarMenu.Footer>
        </SidebarMenu.Collapse>
      </SidebarMenu>
      <main className="main-container container-fluid">
        {children}
      </main>
    </div>
  )

}


export default MainLayout;