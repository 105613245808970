import { SerializedError } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { IDCARD_NUMBER_LENGTH } from '../../constants/app-constants';
import { IIdCardInfo } from './models/IIdCard';
import { useLazyGetIdCardInfoQuery } from './services/idCardSlice';
import IdCardImage from '../../components/IdCard/IdCardImage';

const VerifyIdCard = () => {

  const [trigger, { isError, isFetching, isSuccess, data: cardData, error }] = useLazyGetIdCardInfoQuery();  

  const [cardDetails, setCardDetails] = useState<IIdCardInfo>();
  const [idcardNumber, setIdcardNumber] = useState<string>('');
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess && cardData?.length) {
      console.info('abcdef - > ', cardData.length);
      setCardDetails(cardData[0]);
    }
  }, [isFetching, cardDetails, cardData, isSuccess]);

  const verifyIdCard = (cardNumber: string) => {
    trigger(cardNumber);
  }

  const verificationDone = () => {
    setShowErrorAlert(false);
    setShowSuccessAlert(false);
    setIdcardNumber("");
  };

  const keyUp = (e: any) => {
    if (e.keyCode === 13 && idcardNumber.length === IDCARD_NUMBER_LENGTH) {
      verifyIdCard(idcardNumber);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Card style={{ textAlign: "left", width: "450px" }}>
          <Card.Header>Verify ID Card</Card.Header>
          <Card.Body>
            <Card.Subtitle className="mb-2 text-muted">
              Click on the Id Card Number box and scan card once.
            </Card.Subtitle>
            <div>
              <div>
                <label className="form-label">ID Card Number (Scan Card):</label>
                <input
                  className="form-control"
                  type="text"
                  value={idcardNumber ? idcardNumber : ""}
                  disabled={isError}
                  onKeyUp={keyUp}
                  onChange={(e) => setIdcardNumber(e.target.value)}
                ></input>
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              {!isFetching && (
                <Button
                  variant="primary"
                  disabled={idcardNumber.length !== IDCARD_NUMBER_LENGTH}
                  onClick={() => verifyIdCard(idcardNumber)}
                >
                  Check Id Card
                </Button>
              )}
              {isFetching && <span>Processing Request...</span>}
              {showSuccessAlert && (
                <>
                  <Alert variant="success" style={{ width: "85%" }}>
                    Card verification success.
                  </Alert>
                  <Button
                    variant="success"
                    style={{ height: "30px" }}
                    size="sm"
                    onClick={verificationDone}
                  >
                    Ok
                  </Button>
                </>
              )}
              {showErrorAlert && (
                <>
                  <Alert variant="danger" style={{ width: "85%" }}>
                    {(error as SerializedError).message}
                  </Alert>
                  <Button
                    variant="danger"
                    style={{ height: "30px" }}
                    size="sm"
                    onClick={verificationDone}
                  >
                    Ok
                  </Button>
                </>
              )}
            </div>
          </Card.Footer>
        </Card>
        <span>&nbsp;</span>
        <Card style={{ textAlign: "left", width: "550px" }}>
          <Card.Header>ID Card</Card.Header>
          <Card.Body>
            <div>
              <div style={{ height: "500px", textAlign: "center", padding: "10px" }}>
                {cardDetails && cardDetails.userNumber && cardData && (
                  <IdCardImage userIdNumber={`${cardData[0].userNumber}`}></IdCardImage>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  )

}


export default VerifyIdCard;
