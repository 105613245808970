import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { ILoggedInUser } from '../features/login/models/ILoggedInUser';
import type { RootState } from './store'
import { IValidateOtpResponse } from '../features/login/models/ILoginModels';

type AuthState = {
  user: ILoggedInUser | null,
  accessToken: string | null,
  refreshToken: string | null,
}

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, accessToken: null, refreshToken: null } as AuthState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<IValidateOtpResponse>) => {
      state.user = {
        username: payload.username,
        userDisplayName: payload.userDisplayName,
        role: payload.role
      };
      state.accessToken = payload.bearerToken;
      state.refreshToken = payload.refreshToken;
    },
    setTokens: (state, {payload}: PayloadAction<{bearerToken: string, refreshToken: string}>) => {
      state.accessToken = payload.bearerToken;
      state.refreshToken = payload.refreshToken;
    },
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
    }
  },
})

export const { setCredentials, setTokens, logout } = slice.actions
export default slice.reducer
export const selectCurrentUser = (state: RootState) => state.auth.user
