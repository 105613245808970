import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RouteNameConstants } from '../common/route-data';

type AppState = {
  currentScreen: string,
}

const appSlice = createSlice({
  name: 'app',
  initialState: { currentScreen: RouteNameConstants.home } as AppState,
  reducers: {
    setCurrentScreen: (state, { payload }: PayloadAction<string>) => {
      state.currentScreen = payload;
    },
  },
})

export const { setCurrentScreen } = appSlice.actions
export default appSlice.reducer

