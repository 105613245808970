import { useEffect, useState } from "react";
import { Table as BTable } from 'react-bootstrap';
import { IBusRoute } from "../../features/busRoutes/models/IBusRoute";
import { IStudentRoute } from "../../features/busRoutes/models/IStudentRoute";
import { useLazyGetStudentsInRouteQuery } from "../../features/busRoutes/services/busRoutesSlice";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

export interface IBusRouteReportProps {
  selectedRoute: IBusRoute | undefined;
}


const BusRouteReport = ({ selectedRoute }: IBusRouteReportProps) => {
  const [getStudentsInRouteTrigger] = useLazyGetStudentsInRouteQuery();
  const [data, setData] = useState<IStudentRoute[]>([]);

  useEffect(() => {
    async function update() {
      if (selectedRoute !== undefined) {
        let studentsInRoute = await getStudentsInRouteTrigger(selectedRoute?.routeId).unwrap();
        console.log(`Students in route: `, studentsInRoute);
        let a = [...studentsInRoute].sort((a, b) => {
          if (a.pickupLandmarkName > b.pickupLandmarkName) return 1;
          if (a.pickupLandmarkName < b.pickupLandmarkName) return -1;
          return 0;
        });
        setData(a);
      } else {
        console.log('No route selected.');
      }
    }
    update();
  }, [selectedRoute, getStudentsInRouteTrigger]);


  const columnHelper = createColumnHelper<IStudentRoute>();

  const columns: ColumnDef<IStudentRoute, any>[] = [
    columnHelper.accessor('admissionNumber', {
      header: 'Admission Number',
      cell: info => info.getValue(),
      // footer: info => info.column.id,
    }),
    columnHelper.accessor('studentName', {
      header: 'Student Name',
      cell: info => info.getValue(),
      // footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.className, {
      id: 'Class',
      cell: cell => <span>{cell.getValue()}</span>,
      header: () => <span>Class</span>,
      // footer: info => info.column.id,
    }),
    // columnHelper.accessor(row => row.section, {
    //   id: 'Section',
    //   cell: cell => <span>{cell.getValue()}</span>,
    //   header: () => <span>Section</span>,
    //   // footer: info => info.column.id,
    // }),
    columnHelper.accessor(row => row.pickupLandmarkName, {
      id: 'PickupLandmark',
      cell: ({ cell }) => <>{cell.getValue()}</>,
      header: () => <span>Pickup Landmark</span>,
    }),
    columnHelper.accessor(row => row.dropoffLandmarkName, {
      id: 'DropoffLandmark',
      cell: ({ cell }) => <>{cell.getValue()}</>,
      header: () => <span>Dropoff Landmark</span>,
    }),
    // columnHelper.accessor('Edit', {
    //   cell: ({ cell }) => (
    //     <Button onClick={() => deleteStaff(cell.row.getValue('staffId'), cell.row.getValue('firstName'), cell.row.getValue('lastName'))}>Delete</Button>
    //   )
    // }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });


  return (
    <>
      {selectedRoute ?
        <div>
          Students for Bus Route: {selectedRoute?.routeId} : {selectedRoute?.routeName}. Total: {data.length}
          <BTable striped bordered hover responsive size={'sm'}>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={`${row.getValue('admissionNumber')}`}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>

          </BTable>
        </div>
        : null}
    </>
  )
}

export default BusRouteReport;