import moment from "moment";
import React, { useState } from "react";
import { Card, Button, Alert } from "react-bootstrap";
import { IDCARD_NUMBER_LENGTH, STAFF_ID_NUMBER_LENGTH, STUDENT_ADMISSION_NUMBER_LENGTH } from "../../constants/app-constants";
import { IdCardType, IIdCardInfo } from "./models/IIdCard";
import { useLazyGetIdCardInfoQuery, useRegisterIdCardMutation } from "./services/idCardSlice";
import IdCardImage from "../../components/IdCard/IdCardImage";

export interface IRegisterIdCardProps {
  cardType: IdCardType;
}

const RegisterIdCard = (props: IRegisterIdCardProps) => {

  const [trigger, { isFetching }] = useLazyGetIdCardInfoQuery();
  const [registerIdCard, {  isLoading: registerIsLoading }] = useRegisterIdCardMutation();

  const [idcardNumber, setIdCardNumber] = useState<string>('');
  const [userNumber, setUserNumber] = useState<string>('');
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const [registrationDone, setRegistrationDone] = useState<boolean>(false);

  const cardNumberChanged = (e: any) => {
    setIdCardNumber(e.target.value);
  }

  const userNumberChanged = (e: any) => {
    setUserNumber(e.target.value);
    console.log(e.target.value);
  }


  const verifyIdCard = async () => {
    try {
      console.log('verfy card');
      let res = await trigger(idcardNumber).unwrap();
      if (res && res.length === 0) {
        setIsVerified(true);
      } else {
        setErrMsg(`Card is already registered to ${res[0].userNumber}`);
        setIsVerified(false);
      }
    } catch (err) {
      setErrMsg('Error occurred when verifying card status.');
    }
  };

  const canRegister = () => {
    if (!isVerified || !userNumber || !idcardNumber) {
      return false;
    } 
    return true;
  };

  const register = async () => {
    try {

      let payload: IIdCardInfo = {
        cardNumber: idcardNumber,
        userNumber: userNumber,
        idCardType: props.cardType,
        isRecordDeleted: false,
        cardActivationDateTime: moment().format('YYYY-MM-DD HH:MM:ss'),
        entryDateTime: moment().format('YYYY-MM-DD HH:MM:ss'),
      }
      await registerIdCard(payload).unwrap();
      setIsVerified(false);
      setRegistrationDone(true);
    } catch (err: any) {
      setErrMsg(`Error: ${err.data}`);
      setIsVerified(false);
      setRegistrationDone(true);
    }
  };


  const registrationComplete = () => {
    setIsVerified(false);
    setRegistrationDone(false);
    setIdCardNumber('');
    setUserNumber('');
    setErrMsg('');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '20px' }}>

      <Card style={{ textAlign: 'left', width: '450px' }}>
        <Card.Header>Register {props.cardType.toString()} ID Card</Card.Header>
        <Card.Body>
          <Card.Subtitle className="mb-2 text-muted">Click on the Id Card Number box and scan card once.</Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted">Enter id number and click verify to check if the image matches the id card.</Card.Subtitle>
          <div>
            <div>
              <label className="form-label">ID Card Number (Scan Card):</label>
              <input className="form-control" type="text" value={idcardNumber} onChange={cardNumberChanged}></input>
            </div>
            <div>
              <label className="form-label">{props.cardType === IdCardType.Student ? 'Admission Number:' : 'Employee Number:'}</label>
              <input className="form-control" type="text" value={userNumber} onChange={userNumberChanged}></input>
            </div>

          </div>
        </Card.Body>
        <Card.Footer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
            {!(isFetching || registerIsLoading) && errMsg === '' && !registrationDone && (
              <React.Fragment>
                <Button variant="primary" disabled={idcardNumber.length !== IDCARD_NUMBER_LENGTH} onClick={verifyIdCard}  >
                  Check Card Number
                </Button>

                <Button variant="primary" disabled={!canRegister()} onClick={register} >
                  Register Id Card
                </Button>
              </React.Fragment>
            )}
            {(isFetching || registerIsLoading) && (
              <span>Processing Request...</span>
            )}
            {registrationDone && errMsg === '' && (
              <React.Fragment>
                <Alert variant="success" style={{ width: '85%' }}>Card Registered successfully.</Alert>
                <Button variant="success" style={{ height: '30px' }} size="sm" onClick={registrationComplete}>Ok</Button>
              </React.Fragment>
            )}
            {errMsg && (
              <React.Fragment>
                <Alert variant="danger" style={{ width: '85%' }}>{errMsg}</Alert>
                <Button variant="danger" style={{ height: '30px' }} size="sm" onClick={registrationComplete}>Ok</Button>
              </React.Fragment>

            )}
          </div>
        </Card.Footer>
      </Card>
      <span>&nbsp;</span>
      <Card style={{ textAlign: 'left', width: '650px' }}>
        <Card.Header>ID Card</Card.Header>
        <Card.Body>
          <div>
            <div style={{ height: '500px', textAlign: 'center', padding: '10px' }}>
              {/* <FontAwesomeIcon icon={faUserGraduate} size="9x" /> */}
              {userNumber && (userNumber.length === STUDENT_ADMISSION_NUMBER_LENGTH || userNumber.length === STAFF_ID_NUMBER_LENGTH) && (
                <IdCardImage userIdNumber={`${userNumber}`}></IdCardImage>
              )}
            </div>

          </div>
        </Card.Body>

      </Card>
    </div>
  )
}

export default RegisterIdCard;