import { useCallback, useEffect, useState } from "react";
import _ from 'lodash';
import AppDatePicker from "../../components/AppDatePicker/AppDatePicker";
import { GOOGLE_LIBS } from "../../constants/app-constants";
import Map, { IMapData, IMapDataItem } from "./Map";
import { IBusTrip, IStudentInBus } from "./models/IBusTrip";
import { useLazyGetBusTripInfoQuery } from "./services/mapDataSlice";
import BusRouteSelection from "../../components/BusRouteSelection/BusRouteSelection";
import { IBusRoute } from "../busRoutes/models/IBusRoute";
import ToggleButton from "../../components/ToggleButton/ToggleButton";

const pickup = { start: { hour: 5, minute: 30 }, end: { hour: 10, minute: 0 } };    // pick up starts at 5:30 am, ends at 10:00 am
const dropoff = { start: { hour: 11, minute: 30 }, end: { hour: 20, minute: 0 } };    // drop off starts at 11:30 pm, ends at 8:00 pm
// const landmarkIdadmissionNumber = 2223;

export interface ITripMetaData {
  arrivalTime: string;
  departureTime: string;
  numberOfStudents: number;
}

const BusMap = () => {

  // VRS VJSchool center
  const mapInitialCenter: google.maps.LatLngLiteral = { lat: 17.541443, lng: 78.388241 };
  const [getBusTrip, { isFetching: btIsFetching }] = useLazyGetBusTripInfoQuery();
  const [selectedRoute, setSelectedRoute] = useState<IBusRoute>();
  const [busTrip, setBusTrip] = useState<IBusTrip>();
  const [mapData, setMapData] = useState<IMapData>();
  const [studentsInRoute, setStudentsInRoute] = useState<Number[]>([]);
  const [studentFilter, setStudentFilter] = useState<string>('');
  const [tripMetaData, setTripMetaData] = useState<ITripMetaData>();
  const [forDate, setForDate] = useState<Date>(new Date());
  const [isPickup, setIsPickup] = useState<boolean>(true);
  const [showRoute, setShowRoute] = useState<boolean>(true);

  // **************************
  // pure function to convert supplied datetime into timestamps
  // **************************
  function getPickUpDropOffTs(isPickup: boolean, dt: Date): { startTs: number, endTs: number } {
    if (isPickup) {
      let dtPickupStart = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), pickup.start.hour, pickup.start.minute);
      let dtPickupEnd = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), pickup.end.hour, pickup.end.minute);
      return { startTs: dtPickupStart.valueOf(), endTs: dtPickupEnd.valueOf() };
    } else {
      let dtDropoffStart = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), dropoff.start.hour, dropoff.start.minute);
      let dtDropoffEnd = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), dropoff.end.hour, dropoff.end.minute);
      return { startTs: dtDropoffStart.valueOf(), endTs: dtDropoffEnd.valueOf() };
    }
  }

  // **************************
  // pure function that returns IMapData from busTrip
  // **************************
  const getMapDataFromBusTrip = (busTrip: IBusTrip): IMapData => {
    let retval: IMapData = {
      routeData: [],
      locationData: [],
    };
    busTrip.routeCoordinates.forEach((routeCoords, idx) => {
      retval.routeData.push({
        id: routeCoords.deviceId,   // TODO: this needs to be BusId
        coords: routeCoords.coords,
        title: `${routeCoords.deviceId} - ${routeCoords.deviceName} - ${routeCoords.deviceDateTime}`
      });
    });
    busTrip.students.forEach((student, idx) => {
      retval.locationData.push({
        id: student.admissionNumber.toString(),
        coords: student.coords,
        title: `${student.admissionNumber} - ${student.fullName} - ${student.deviceDateTime}`
      });
    });
    return retval;
  }

  const getStudentIdsFromBusTrip = (busTrip: IBusTrip): Number[] => {
    let s: Number[] = [];
    busTrip.students.forEach((student: IStudentInBus, idx: any) => {
      s.push(student.admissionNumber);
    });
    s = _.uniq(s);
    s = _.sortBy(s);
    return s;
  }

  const getMapDataForBusTrip = useCallback(async (busId: string, dt: Date, ampm: boolean) => {
    if (busId.length > 0) {
      let { startTs, endTs } = getPickUpDropOffTs(ampm, dt);
      let res = await getBusTrip({ busId: busId, startTs: startTs, endTs: endTs }, true).unwrap();
      setBusTrip(res);
      let mapData = getMapDataFromBusTrip(res);
      setMapData(mapData);

      setStudentsInRoute(getStudentIdsFromBusTrip(res));

      console.log('setting departure and arrival times');
      setTripMetaData({
        departureTime: new Date(res.departureTime).toLocaleTimeString(),
        arrivalTime: new Date(res.arrivalTime).toLocaleTimeString(),
        // numberOfStudents: Number(res.students?.length)
        numberOfStudents: Number(res.totalStudentsInBus)
      });
      console.log(`Fetched ${res?.students.length} students in bus during ${ampm ? 'pickup' : 'dropoff'} in '${busId}'`);
    } else {
      console.log('Waiting for device/route selection.');
    }
  }, [getBusTrip]);

  useEffect(() => {
    getMapDataForBusTrip(selectedRoute?.routeName ?? '', forDate, isPickup);
  }, [selectedRoute, forDate, isPickup, getMapDataForBusTrip]);

  useEffect(() => {
    console.log('BusMap mounted');
    return () => console.log('BusMap unmounted.');
  }, [])



  // const studentSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
  const studentSelected = useCallback((admNumber: string, students: IStudentInBus[], routeItems: IMapDataItem[] ): IMapData => {
    // var sid = e.target.value;
    var sid: number = Number(admNumber);
    console.log(`Student selected: ${sid}`);
    
      let filtered: IMapDataItem[] = []
      students.forEach((student) => {
        if (sid !== 0 && student.admissionNumber === sid) {
          let v: IMapDataItem = {
            id: student.admissionNumber.toString(),
            coords: student.coords,
            title: `${student.admissionNumber} - ${student.fullName} - ${student.deviceDateTime}`
          };
          filtered.push(v);
        } else if (sid === 0) {
          let v: IMapDataItem = {
            id: student.admissionNumber.toString(),
            coords: student.coords,
            title: `${student.admissionNumber} - ${student.fullName} - ${student.deviceDateTime}`
          };
          filtered.push(v);
        }
      });
      let newMapData: IMapData = {
        routeData: routeItems,
        locationData: filtered
      }
      // setMapData(() => newMapData);
      return newMapData;
    
  }, []);

  useEffect(() => {
    console.log('stuent ilter upa');
    studentSelected(studentFilter, busTrip?.students ?? [], mapData?.routeData ?? []);
  }, [studentSelected, studentFilter, busTrip?.students, mapData?.routeData,]);

  // useEffect(() => {
  //   console.log('updating mapdata');
  //   if (busTrip) {
  //     let originalMapData = getMapDataFromBusTrip(busTrip);
  //     let newMapData: IMapData = {
  //       locationData: originalMapData.locationData,
  //       routeData: showRoute ? originalMapData.routeData ?? [] : []
  //     };
  //     setMapData(() => newMapData);
  //   }
  // }, [showRoute, busTrip]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', justifyContent: 'flex-start' }}>
        <div style={{ marginTop: '3px', marginRight: '10px' }}>
          <BusRouteSelection routeSelected={e => setSelectedRoute(e)} />
        </div>
        {/* <input style={{ width: '75px' }} type='text' value={admissionNumber} onChange={(e) => setAdmissionNumber(Number(e.target.value))}></input> */}
        <select name="dropdown" id="studentDropdown" value={studentFilter?.toString()} onChange={e => setStudentFilter(e.target.value)}>
          <option value={''}>Choose a Student</option>
          {
            studentsInRoute?.map((item, idx) => {
              return (
                <option key={idx} value={item.toString()}>{item.toString()}</option>
              )
            })
          }
        </select>
        <ToggleButton trueText="Show&nbsp;Route" falseText="Hide&nbsp;Route" defaultChecked={true} onChange={setShowRoute} disabled={false} textWidth={120} />
        <AppDatePicker onToggled={(selection) => setIsPickup(selection)} onDateSelected={(dt) => setForDate(dt)} />
      </div>
      <div style={{paddingLeft: 5, borderRadius: 3, fontWeight: '700', backgroundColor:'maroon', color: 'white' }}>
          {(btIsFetching) ? `Working...` : `Departure: ${tripMetaData?.departureTime ?? 'Not Known'}, Arrival: ${tripMetaData?.arrivalTime ?? 'Not Known'}, Students: ${tripMetaData?.numberOfStudents}`}
        </div>
      <Map
        libs={GOOGLE_LIBS}
        mapCenter={mapInitialCenter}
        mapData={mapData}
        showRoute={showRoute}
      />
    </>
  )

}

export default BusMap;