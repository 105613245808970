import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { SPECIALMARKERADMISSIONNUMBER } from '../../constants/app-constants';
import { IGeolocation } from './models/IGeoLocation';

// const mapInitialCenter: google.maps.LatLngLiteral = { lat: 48.8584, lng: 2.2945 };   // paris

export interface IMapDataItem {
  id: string;
  title: string;
  coords: IGeolocation;
}

export interface IMapData {
  routeData: IMapDataItem[];
  locationData: IMapDataItem[];
}

export interface IAppMapProps {
  libs: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[];
  mapCenter: google.maps.LatLng | google.maps.LatLngLiteral;
  mapData?: IMapData;
  showRoute: boolean;
}

const Map = ({ libs, mapCenter, mapData, showRoute }: IAppMapProps) => {

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : 'AIzaSyDszHSzn1Wu2QPT7l8CX52BJtst7q67e-k',
    // libraries: libs
  });

  const [map, setMap] = useState<google.maps.Map>();
  const [actualMapCenter, setActualMapCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral>(mapCenter);

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: true,
    clickableIcons: false,
    styles: [
      { featureType: "poi.business", stylers: [{ visibility: "off" }] },
      { featureType: "transit", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
      { featureType: "poi.place_of_worship", stylers: [{ visibility: "off" }], },
      { featureType: "poi.park", stylers: [{ visibility: "off" }], },
      { featureType: "poi.government", stylers: [{ visibility: "off" }], },
      { featureType: "poi.medical", stylers: [{ visibility: "off" }], },
      { featureType: "poi.school", stylers: [{ visibility: "off" }], },
      { featureType: "poi.attraction", stylers: [{ visibility: "off" }], },
      { featureType: "poi.sports_complex", stylers: [{ visibility: "off" }], },
    ],
  };

  // const panTo = (mapLoc: google.maps.LatLng | google.maps.LatLngLiteral) => {
  //   map?.panTo(mapLoc);
  // }

  useEffect(() => {
    console.log('Map mounted');
    return () => console.log('Map unmounted');
  }, []);  

  useEffect(() => {
    let centerChangedListener = map?.addListener('dragend', () => {
      setActualMapCenter(map?.getCenter() ?? mapCenter);
      console.log('Update CENTER');    
    });

    return () => {
      centerChangedListener?.remove();
    }
  }, [map, mapCenter]);
  
  return (
    <>
      {!isLoaded ?
        <div>Loading map...</div> :
        <>
          {/* <Button onClick={() => panTo(mapCenter)}>Pan to Center</Button> */}
          <div style={{ height: '89vh', width: '94vw' }}>
            <GoogleMap
              center={actualMapCenter}
              zoom={16}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              options={options}
              onLoad={map => setMap(map)}
            >
              {
                showRoute && mapData &&
                mapData?.routeData.length > 0 &&
                mapData?.routeData.map((routeData, idx) => (
                  <Marker
                    key={idx}
                    options={{
                      icon: {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 3,
                        fillColor: 'blue',
                        fillOpacity: 0.6,
                        strokeWeight: 1,
                        rotation: routeData.coords.heading,
                      },
                    }}
                    // title={`${busMarker.deviceId} - ${busMarker.deviceName} - ${busMarker.deviceDateTime}`}
                    title={`${routeData.title}`}
                    position={{ lat: routeData.coords.latitude, lng: routeData.coords.longitude }}
                  />
                ))
              }
              {
                mapData &&
                mapData.locationData.length > 0 &&
                mapData.locationData.map((location, idx) => (
                  <Marker
                    key={idx}
                    icon= {location.id !== `${SPECIALMARKERADMISSIONNUMBER}` ? "/images/markers/marker_redS.png" : "/images/markers/marker_blueP.png"}
                    // title={`${studentLocation.admissionNumber} - ${studentLocation.fullName} - ${studentLocation.deviceDateTime}`}
                    title={`${location.title}`}
                    position={{ lat: location.coords.latitude, lng: location.coords.longitude }}
                    // onClick={() => studentMarkerClicked(studentLocation)}
                  />
                ))
              }

            </GoogleMap>
          </div>
        </>
      }
    </>
  )
}

export default Map;
