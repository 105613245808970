import { Button } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { IBusRoute } from '../../features/busRoutes/models/IBusRoute';
import { ILandmark } from '../../features/busRoutes/models/ILandmark';
import LandmarkCard from '../LandmarkCard/LandmarkCard';
import TimelineIcon from './TimelineIcon';
// import landmarkicon from '../../../src/assets/icons/iconlandmark.png';


export interface ITimelineProps {
  busRoute?: IBusRoute;
  icon?: JSX.Element;
}

const RouteTimeline = ({ busRoute, icon }: ITimelineProps) => {

  function getStyleForIndex(idx: number): React.CSSProperties {
    if (idx % 2 === 0) {
      return { background: 'white' }
    }
    return { background: 'lightgrey' };
  }

  function getArrowStyle(idx: number): React.CSSProperties {
    if (idx % 2 === 0) {
      return { borderRight: `10px solid white` };
    }
    return { borderRight: `10px solid lightgrey` };
  }

  const timelineIconClicked = () => {
    console.log('clicked');
  }

  return (
    <div style={{ marginTop: '5px', border: '1px', borderStyle: 'solid', borderColor: 'green', borderRadius: '6px', marginBottom: '15px' }}>
      <div style={{ fontSize: '32px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', fontWeight: '700', textAlign: 'center', width: '100%', backgroundColor: 'green', color: 'white', height: '50px' }}>
        {`${busRoute?.routeName}`}
      </div>
      <div style={{ fontSize: '24px', fontWeight: '400', textAlign: 'center', width: '100%', backgroundColor: 'green', color: 'white', height: '50px' }}>
        <span style={{ fontSize: '26px', fontWeight: '500' }}> {`${busRoute?.pickupLandmarks.length} `}</span>Stops. <span style={{ fontSize: '26px', fontWeight: '500' }}> {`${busRoute?.studentCount}`}</span> Students.
        &nbsp;&nbsp;<Button onClick={() => alert('work in progress.')} style={{backgroundColor: '', color: ''}}>Send SMS (to all student in route)</Button>
      </div>
      <VerticalTimeline>
        {busRoute?.pickupLandmarks.map((landmark: ILandmark, idx) => {
          return (
            <VerticalTimelineElement
              key={idx}
              // className={styles.verticalTimelineElementWork}
              contentStyle={getStyleForIndex(idx)}
              contentArrowStyle={getArrowStyle(idx)}
              date={`${landmark.landmarkName}`}
              iconStyle={{ background: 'green', color: '#fff' }}
              icon={<TimelineIcon numberText={landmark.seqNumber} color={'white'} />}
              iconOnClick={() => timelineIconClicked()}
            >
              <LandmarkCard key={idx} landmark={landmark} showStudents={true} />

            </VerticalTimelineElement>)
        })
        }
      </VerticalTimeline>
    </div>
  )

}

export default RouteTimeline;