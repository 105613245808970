import { ReactNode, useState } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import { IBusRoute } from "../../features/busRoutes/models/IBusRoute";
import BusRouteSelection from "../BusRouteSelection/BusRouteSelection";
import { Card } from 'react-bootstrap';
import { IBusRouteLandmarkCreateRequest } from '../../features/busRoutes/models/ILandmark';
import { DropoffPickupEnum } from '../../features/student/models/IStudent';
import { Select } from '@chakra-ui/react';
import { useCreateNewBusRouteLandmarkMutation } from '../../features/busRoutes/services/busRouteLandmarksSlice';

const ManageLandmarksForRoute = () => {
  const [busRoute, setBusRoute] = useState<IBusRoute>();

  const [createBusRouteLandmarkTrigger] = useCreateNewBusRouteLandmarkMutation();
  const [msg, setMsg] = useState<string>('');
  const [landmark, setLandmark] = useState<IBusRouteLandmarkCreateRequest>(initCreateLandmark());
  const [isUpdate] = useState<boolean>(false);



  const routeSelect = (busRoute: IBusRoute) => {
    setTimeout(() => {
      // this setTimeout is to give the UI a chance to re-render before supplying it with new input. 
      // without this, there is no animation.
      setBusRoute(busRoute);
      setLandmark({ ...initCreateLandmark(), busRouteId: busRoute.routeId });
      setMsg(() => '');

      console.log(`Pickup Landmarks count: ${busRoute.pickupLandmarks.length}, Dropoff landmarks count: ${busRoute.dropoffLandmarks.length}`);
    }, 50);
    setBusRoute(undefined);
  }

  function initCreateLandmark(): IBusRouteLandmarkCreateRequest {
    return {
        landmarkId: '',
        busRouteId: '',        
        landmarkName:  '',
        landmarkDirection: DropoffPickupEnum.Dropoff,
        approximateBusTime: 0,
        latitude: 0,
        longitude: 0,
        coordinates:[0,0],
    }
  }

  const handleChange = (property: keyof IBusRouteLandmarkCreateRequest, value: string | boolean) => {
    // setStaff(prev => 
    //     ({ ...prev, [event.target.name]: [event.target.value] }));
    console.log(`---> ${property}, ${value}`);
    if (value === 'on') {
        value = !value;
    }
    setLandmark({ ...landmark, [property]: value });
  };

  function getEnumEntries(myenum: typeof DropoffPickupEnum ): ReactNode[] {
    let retval: ReactNode[] = [];
    for (const [key] of Object.entries(myenum)) {
        //console.log(`${key}, value: ${value}`);
        if (key.length > 1) {
            retval.push(<option value={`${key}`} key={`${key}`}>{key}</option>);
        }
    }
    return retval;
  }

  
  const createBusRouteLandmark = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMsg(() => '');
        e.preventDefault();
        if (!isUpdate) {
            console.log('Creating landmark', landmark);

            try {
                let res = await createBusRouteLandmarkTrigger(landmark).unwrap();
                console.log('success', res);
                setMsg(() => 'Success adding Landmark. Logout, close browser, open new browser and login to see latest landmarks.');
            } catch (error: any) {
                console.error('error creating student', error);
                setMsg(() => error.data);
            }
        } 
        // else {
        //     console.log('Updating busroutelandmark', landmark);
        //     try {
        //         let res = await updateStudentTrigger(student).unwrap();
        //         console.log('success', res);
        //         setMsg('Success updating Student');
        //     } catch (error: any) {
        //         console.error('error updating student', error);
        //         setMsg(error.data);
        //     }
        // }
    }

    const clearForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        console.log('Clearform ');
        setLandmark(initCreateLandmark);
        setMsg('');
    }

    // function refreshBusRouteData() {
    //     console.log('resetting...');
    //     busRoutesApi.util.resetApiState();
    //     busRouteLandmarksApi.util.resetApiState();
    // }


  return (
    <>
      <BusRouteSelection showRouteSelectionText={true} showLandmarkSelection={false} routeSelected={routeSelect} />
      <br/>
      {busRoute ?
        <>
            {/* <button onClick={() => refreshBusRouteData()}>Refresh Data</button> */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: "20px",
                    }}
                >
                    <Card style={{ textAlign: "left", width: "600px" }}>
                        <Card.Header>Create New Landmark for Bus Route: {busRoute?.routeName}</Card.Header>
                        <Card.Body>
                            <Card.Subtitle className="mb-2 text-muted">
                                <h3 className="h3 mb-3 fw-normal">{msg}</h3>
                            </Card.Subtitle>
                            <main className="form w-100 m-auto">
                                <div className='container'>
                                    <form className='row'>
                                        <div className="form-floating1 m-3">
                                            <label >Landmark Name:</label>
                                            <input type="text" className="form-control" id="floatingInput" placeholder="Enter Landmark Name"
                                                value={landmark.landmarkName} onChange={(e) => handleChange('landmarkName', e.target.value)} />
                                        </div>
                                
                                        <div className="col form-floating1 m-3">
                                            <label >Landmark Direction:</label>
                                            <Select placeholder='Select' value={landmark.landmarkDirection} onChange={(e) => handleChange('landmarkDirection', e.target.value)}>
                                                {getEnumEntries(DropoffPickupEnum)}
                                            </Select>
                                        </div>

                                        <div className="m-auto">
                                            <button className="w-25 m-3 btn btn-md btn-primary" onClick={(e) => createBusRouteLandmark(e)} >{isUpdate ? 'Update' : 'Create'}</button>
                                            <button className="w-25 m-3 btn btn-md btn-danger" onClick={(e) => clearForm(e)}>Cancel</button>
                                        </div>
                                        {/* <div><h1 className="h3 mb-3 fw-normal">{msg}</h1></div> */}
                                    </form>
                                </div>
                            </main>
                        </Card.Body>
                        <Card.Footer>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "baseline",
                                }}
                            >
                            </div>
                        </Card.Footer>
                    </Card>
                </div>
        </>
        : null
      }

    </>
  )

}

export default ManageLandmarksForRoute;