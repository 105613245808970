export const STUDENT_ADMISSION_NUMBER_LENGTH = 4;
export const STAFF_ID_NUMBER_LENGTH = 8;
export const IDCARD_NUMBER_LENGTH = 10;
export const MIN_STUDENT_ADMISSION_NUMBER = 999;
export const MAX_STUDENT_ADMISSION_NUMBER = 10000;

// export const GOOGLE_MAPS_API_KEY = 'AIzaSyDszHSzn1Wu2QPT7l8CX52BJtst7q67e-k';
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY ? process.env.REACT_APP_GOOGLE_MAP_API_KEY : '';
export const GOOGLE_LIBS:("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = ['places'];



export const TAGS = {
  StudentInfo: 'StudentInfo',
  StudentBusRoutes: 'StudentBusRoutes',
  IdCardInfo: 'IdCardInfo',
  DeviceLocations: 'DeviceLocations',
  StudentLocations: 'StudentLocations',
  StudentsInBus: 'StudentsInBus',
  Staff: 'Staff',
  Reports: 'Reports',
};


export const SPECIALMARKERADMISSIONNUMBER = 2618;
