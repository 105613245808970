import { useState } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import { IBusRoute } from "../../features/busRoutes/models/IBusRoute";
import BusRouteReport from '../BusRouteReport/BusRouteReport';
import BusRouteSelection from "../BusRouteSelection/BusRouteSelection";
import RouteTimeline from '../RouteTimeline/RouteTimeline';
import ToggleButton from '../ToggleButton/ToggleButton';

const RouteManagement = () => {
  const [busRoute, setBusRoute] = useState<IBusRoute>();
  const [showRouteLine, setShowRouteLine] = useState<boolean>(true);

  const routeSelect = (busRoute: IBusRoute) => {
    setTimeout(() => {
      // this setTimeout is to give the UI a chance to re-render before supplying it with new input. 
      // without this, there is no animation.
      setBusRoute(busRoute);
      console.log(`Pickup Landmarks count: ${busRoute.pickupLandmarks.length}, Dropoff landmarks count: ${busRoute.dropoffLandmarks.length}`);
    }, 50);
    setBusRoute(undefined);
  }


  const toggleShow = () => {
    setShowRouteLine(prev => !prev);

  }


  return (
    <>
      <BusRouteSelection showRouteSelectionText={true} showLandmarkSelection={false} routeSelected={routeSelect} />
      {busRoute ?
        <>
          <ToggleButton trueText='Route&nbsp;Line' falseText='Students List' textWidth={125} onChange={() => toggleShow()} defaultChecked={true} disabled={false} />
          {showRouteLine ? 
            <RouteTimeline busRoute={busRoute} />
            : 
            <BusRouteReport selectedRoute={busRoute} />
          }
        </>
        :
        null
      }
    </>
  )
}

export default RouteManagement;