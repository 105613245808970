// export const IMAGESROOT = process.env.REACT_APP_IMAGES_ROOT_URL ? process.env.REACT_APP_IMAGES_ROOT_URL : 'https://services.vrsvjschool.com/';
export const IMAGE_CARD_NOT_FOUND = `images/id/notfound.png`;
export const IMAGE_CARD_EMPTY_TEMPLATE = `images/idempty/student_id_empty_low.png`;

// there are two branches in github with github actions configured for auto compile and push. 
//    - master 
//    - test
// the environment variable that is set during 'npm run build' is the branch name that triggered
// the build. We want to point to different server base urls for different deployments. 
//    -master build -> point to vrsservices.kernelsecure.com 
//    -test build -> point to vrsservicestest.kernelsecure.com 
// additional info: see github actions environment variables: 
//  https://docs.github.com/en/enterprise-cloud@latest/actions/learn-github-actions/environment-variables#default-environment-variables
// and the .github/workflows/*.yml file in this project. look for env var REACT_APP_ENV
export const ENV = process.env.REACT_APP_ENV === 'test' ? 'test' : '';

export const SERVER_BASE_URL = `https://vrsservices${ENV}.kernelsecure.com/`;
// export const SERVER_BASE_URL = `http://localhost:5289/`;

// login
export const LOGIN_POST_URL = `api/AuthenticateAdmin/loginadmin`;
export const VALIDATEOTP_POST_URL = `api/Authenticate/validateOTP`;
export const REFRESHTOKEN_GET_URL = `api/Authenticate/refreshtoken`;

// student
export const STUDENTINFO_GET_URL = `api/Student/studentinfo?studentNumber=`;
export const STUDENT_INFO_ALL_GET_URL = `api/Admin/Student/getall`;
export const STUDENT_CREATE_POST_URL = 'api/Admin/Student/create';
export const STUDENT_UPDATE_POST_URL = 'api/Admin/Student/update';
export const STUDENT_DELETE_POST_URL = 'api/Admin/Student/remove';

// bus routes
export const ALLBUSROUTES_GET_URL = `api/BusRoutes/GetAllBusRoutes`;
export const STUDENTBUSROUTE_GET_URL = `api/BusRoutes/getForStudent?identification=`;
export const UPDATESTUDENTBUSROUTE_POST_URL = `api/BusRoutes/updateForStudent`;
export const STUDENTS_IN_ROUTE_GET_URL = `api/BusRoutes/studentsInRoute?routeId=`;

// landmarks
export const ALLLANDMARKS_POST_URL = `api/BusRoutes/CreateBusRouteLandmark`;

// id card
export const IDCARDINFO_GET_URL = `api/Admin/idCard?cardNumber=`;
export const REGISTERIDCARDINFO_POST_URL = `api/Admin/idCard/create`;
export const IDCARD_IMAGE_GET_URL = `${SERVER_BASE_URL}api/Admin/idCard/image?idNumber=`;   // have to include baseurl as we are using fetch directly and not using RTK query to make the get image api request

// map data locations (bus and student)
export const BUS_LOCATIONS_GET_URL = `api/Map/locations/bus`;
export const STUDENT_LOCATIONS_GET_URL = `api/Map/locations/student`;
export const STUDENTSINBUS_GET_URL = `api/Map/locations/bustripinfo`;


// staff 
export const STAFF_CREATE_POST_URL = `api/Admin/staff/create`;
export const STAFF_INFO_ALL_GET_URL = `api/Admin/staff/getall`;
export const STAFF_INFO_GET_URL = `api/Admin/staff`;
export const STAFF_DELETE_POST_URL = `api/Admin/staff/remove?id=`;


// reports
export const REPORTS_GEN_STUDENT_ATTENDANCE_TRANSPORT_GET_URL = `api/Reports/generateStudentAttendanceAndTransportReport`;
export const REPORTS_GEN_STUDENT_MONTHLY_ATTENDANCE_GET_URL = `api/Reports/generateStudentMonthlyAttendanceReport`;
export const REPORTS_GEN_STAFF_ATTENDANCE_GET_URL = `api/Reports/generateStaffAttendanceReport`;