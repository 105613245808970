import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '../../../common/BaseQueryWithReAuth';
import { TAGS } from '../../../constants/app-constants';
import { STUDENTBUSROUTE_GET_URL, STUDENTINFO_GET_URL, STUDENT_CREATE_POST_URL, STUDENT_DELETE_POST_URL, STUDENT_INFO_ALL_GET_URL, STUDENT_UPDATE_POST_URL, UPDATESTUDENTBUSROUTE_POST_URL } from '../../../constants/service-constants';
import { IStudentBusRouteLandmarkSelection, IStudentInfoRequest, IStudentInfoResponse } from '../models/IStudent';


export const studentApi = createApi({
  reducerPath: 'studentApi',
  // baseQuery: fetchBaseQuery({ baseUrl: `${SERVER_BASE_URL}` }),
  baseQuery: baseQueryWithReauth,
  tagTypes: [TAGS.StudentInfo, TAGS.StudentBusRoutes],
  endpoints: (builder) => ({
    getAllStudents: builder.query<IStudentInfoResponse[], void>({
      query: () => `${STUDENT_INFO_ALL_GET_URL}`,
      providesTags: [TAGS.StudentInfo],
    }),

    getStudentByAdmissionNumber: builder.query<IStudentInfoResponse, number>({
      query: (admissionNumber) => `${STUDENTINFO_GET_URL}${admissionNumber}`,
      providesTags: [TAGS.StudentInfo],
    }),

    getBusRouteSelectionForStudent: builder.query<IStudentBusRouteLandmarkSelection, string | void>({
      query: (identification) => `${STUDENTBUSROUTE_GET_URL}${identification}`,
      providesTags: [TAGS.StudentBusRoutes],
      async onQueryStarted(identification, { getState, queryFulfilled }) {
        // onStart side-effect
        try {
          await queryFulfilled;
          // console.warn(identification, getState().studentApi);
        } catch (err) {
          console.error('There was an error in onQueryStarted in studentApiSlice', err);
        }
      }
    }),
    updateStudentBusRoute: builder.mutation<IStudentBusRouteLandmarkSelection, IStudentBusRouteLandmarkSelection>({
      query(selection) {
        return {
          url: `${UPDATESTUDENTBUSROUTE_POST_URL}`,
          method: 'POST',
          body: selection
        }
      },
      invalidatesTags: [TAGS.StudentBusRoutes],
    }),

    
    createNewStudent: builder.mutation<IStudentInfoResponse, IStudentInfoRequest>({
      query({id,...rest}) {
        return {
          url: `${STUDENT_CREATE_POST_URL}`,
          method: 'POST',
          body: rest
        }
      },
      invalidatesTags: [TAGS.StudentInfo],
    }),

    updateStudent: builder.mutation<IStudentInfoResponse, IStudentInfoRequest>({
      query(stu) {
        return {
          url: `${STUDENT_UPDATE_POST_URL}`,
          method: 'POST',
          body: stu
        }
      },
      invalidatesTags: [TAGS.StudentInfo],
    }),

    deleteStudent: builder.mutation<boolean, string>({
      query(id) {
        return {
          url: `${STUDENT_DELETE_POST_URL}${id}`,
          method: 'POST'
        }
      },
      invalidatesTags: [TAGS.StudentInfo],
    }),

  }),

});

export const { 
  useGetAllStudentsQuery, 
  useGetStudentByAdmissionNumberQuery, 
  useLazyGetStudentByAdmissionNumberQuery,
  useGetBusRouteSelectionForStudentQuery, 
  useUpdateStudentBusRouteMutation,
  useCreateNewStudentMutation,
  useUpdateStudentMutation,
  useDeleteStudentMutation,
} = studentApi
