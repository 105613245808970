import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '../../../common/BaseQueryWithReAuth';
import { ALLLANDMARKS_POST_URL } from '../../../constants/service-constants';
import { IBusRouteLandmarkCreateRequest, ILandmark } from '../models/ILandmark';
import { TAGS } from '../../../constants/app-constants';


export const busRouteLandmarksApi = createApi({
  reducerPath: 'busRouteLandmarksApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [TAGS.StudentBusRoutes],
  endpoints: (builder) => ({

    createNewBusRouteLandmark: builder.mutation<ILandmark, IBusRouteLandmarkCreateRequest>({
      query({...rest}) {
        return {
          url: `${ALLLANDMARKS_POST_URL}`,
          method: 'POST',
          body: rest
        }
      },
      invalidatesTags: [TAGS.StudentBusRoutes],
    }),

  }),
});

export const { useCreateNewBusRouteLandmarkMutation } = busRouteLandmarksApi
