import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { setCredentials } from '../app/authSlice';
import { useAppDispatch } from '../app/store';
import { RouteNameConstants } from '../common/route-data';
import { IValidateOtpResponse } from '../features/login/models/ILoginModels';
import BusMap from '../features/maps/BusMap';
import MainLayout from './MainLayout';
import RequireAuth from './RequireAuth';

export function SecureOutlet() {
  const location = useLocation();
  const [showBusMap, setShowBusMap] = useState<string>('none');

  useEffect(() => {
    console.log(`SecureOutlet mounted.`);
    return () => console.log(`SecureOutlet unmounted`);
  }, []);


  useEffect(() => {
    if (location.pathname === `/${RouteNameConstants.transportdashboard}`) {
      setShowBusMap('block');
    } else {
      setShowBusMap('none');
    }
  }, [location.pathname]);

  // check if there is an auth token in localStorage
  let dispatch = useAppDispatch();
  let auth = localStorage.getItem('auth');
  if (auth !== null) {
    let a = JSON.parse(auth) as IValidateOtpResponse;
    dispatch(setCredentials(a));

    return (
      <>
        {/* <NavbarOffcanvas/> */}
        <MainLayout>
          <div style={{display: `${showBusMap}`}}>
            <RequireAuth><BusMap /></RequireAuth>
          </div>
          <Outlet />
        </MainLayout>
      </>
    )
  }

  return <Navigate to="/login" state={{ from: location }} />
}
