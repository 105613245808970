export enum StudentTypeEnum {
    DayStudent = 0,
    ResidentStudent,
    ResidentStcStudent,
    DayStcStudent
}

export enum TransportTypeEnum {
    Hostel = 0,
    OwnTransport,
    SchoolTransport,
}

export enum StudentGenderEnum {
    Boy = 0,
    Girl,
}

export enum DropoffPickupEnum {
    Dropoff = 'Dropoff',
    Pickup = 'Pickup',
}

export interface IStudentInfoRequest {
    id: string,
    admissionNumber: number,
    title: string, 
    fullName: string,
    firstName: string,
    middleName: string,
    lastName: string,
    gender: StudentGenderEnum,
    dateOfBirth: string,
    emailAddress: string,
    fatherName: string,     
    fatherMobileNumber: string,
    motherName: string,
    motherMobileNumber: string,
    primaryEmailAddress: string,
    permanentAddress: string,
    currentAddress: string,
    joiningYear: number,
    className: number,
    section: string,
    studentType: StudentTypeEnum,
    transportType: TransportTypeEnum,
    isActive: boolean,
}

export interface IStudentInfoResponse extends IStudentInfoRequest{

}

// export interface IStudentInfoResponse {
//     id: string,
//     admissionNumber: number,
//     title: string, 
//     fullName: string,
//     firstName: string,
//     middleName: string,
//     lastName: string,
//     gender: string,
//     dateOfBirth: string,
//     emailAddress: string,
//     fatherMobileNumber: string,
//     motherMobileNumber: string,
// }

export interface IStudentBusRouteLandmarkSelection {
    admissionNumber: number;
    routeId: string;
    routeName: string;
    // landmarkSeqNum: number;
    pickupLandmarkName: string;
    dropoffLandmarkName: string;
    isOwnTransport: boolean;
}