import { Select } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { Card } from 'react-bootstrap';
import { IStudentInfoRequest, StudentGenderEnum, StudentTypeEnum, TransportTypeEnum } from './models/IStudent';
import { useCreateNewStudentMutation, useLazyGetStudentByAdmissionNumberQuery, useUpdateStudentMutation } from './services/studentSlice';
// import styles from './Student.module.scss';

const Student = () => {
    const [student, setStudent] = useState<IStudentInfoRequest>(initStudent);
    const [getStudentByAdmNumTrigger] = useLazyGetStudentByAdmissionNumberQuery();
    const [createStudentTrigger] = useCreateNewStudentMutation();
    const [updateStudentTrigger] = useUpdateStudentMutation();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [msg, setMsg] = useState<string>('');
    // const { data, error, isLoading, refetch } = useGetBusRoutesQuery();
    // const dispatch = useAppDispatch();

    // const resetAllMapReduxState = () => {
    //     dispatch(mapDataApi.util.resetApiState());
    // }


    function initStudent(): IStudentInfoRequest {
        return {
            id: '',
            admissionNumber: 0,
            title: '',
            fullName: '',
            middleName: '',
            firstName: '',
            lastName: '',
            gender: StudentGenderEnum.Boy,
            dateOfBirth: '',
            emailAddress: '',
            fatherName: '',
            fatherMobileNumber: '',
            motherName: '',
            motherMobileNumber: '',
            primaryEmailAddress: '',
            permanentAddress: '',
            currentAddress: '',
            joiningYear: new Date().getFullYear(),
            className: 0,
            section: '',
            studentType: StudentTypeEnum.DayStudent,
            transportType: TransportTypeEnum.OwnTransport,
            isActive: true,
        }
    }

    function getEnumEntries(myenum: typeof StudentTypeEnum | typeof StudentGenderEnum | typeof TransportTypeEnum): ReactNode[] {
        let retval: ReactNode[] = [];
        for (const [key] of Object.entries(myenum)) {
            //console.log(`${key}, value: ${value}`);
            if (key.length > 1) {
                retval.push(<option value={`${key}`} key={`${key}`}>{key}</option>);
            }
        }
        return retval;
    }


    const createStudent = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (!isUpdate) {
            console.log('Creating student', student);

            try {
                let res = await createStudentTrigger(student).unwrap();
                console.log('success', res);
                setMsg('Success adding Student');
            } catch (error: any) {
                console.error('error creating student', error);
                setMsg(error.data);
            }
        } else {
            console.log('Updating student', student);

            try {
                let res = await updateStudentTrigger(student).unwrap();
                console.log('success', res);
                setMsg('Success updating Student');
            } catch (error: any) {
                console.error('error updating student', error);
                setMsg(error.data);
            }
        }
    }

    const clearForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        console.log('Clearform ');
        setStudent(initStudent);
        setMsg('');
    }


    const handleChange = (property: keyof IStudentInfoRequest, value: string | boolean) => {
        // setStaff(prev => 
        //     ({ ...prev, [event.target.name]: [event.target.value] }));
        console.log(`---> ${property}, ${value}`);
        if (value === 'on') {
            value = !value;
        }
        setStudent({ ...student, [property]: value });
    };


    const getStudent = async () => {
        if (student.admissionNumber > 999 && student.admissionNumber < 10000) {
            // get the student from database by admission number
            let studentAdmNum = student.admissionNumber;    // store it for later
            try {
                const result = await getStudentByAdmNumTrigger(student.admissionNumber).unwrap();
                console.log('fulfilled', result, student.admissionNumber, result.admissionNumber === student.admissionNumber);
                if (Number(result.admissionNumber) === Number(student.admissionNumber)) {
                    setStudent(result);
                    console.log('setStudent', result);
                    setIsUpdate(true);
                    setMsg(`Student found in Database.`);
                } else {
                    setIsUpdate(false);
                    setMsg(`Admission Number ${student.admissionNumber} is available for New Student.`);
                }
            } catch (error) {
                console.error('rejected', error);
                setIsUpdate(false);
                let init = initStudent();
                init.admissionNumber = studentAdmNum;
                setStudent(init);
                setMsg('');
                //setMsg(`There was a problem checking for Admission Number ${student.admissionNumber} ${studentGetError}`);
            }
        } else {
            console.log('Will not search for Admission number ', student.admissionNumber);
        }

    }


    return (
        // <>
        //     <div className={styles.main}>Secure component Student</div>&nbsp;
        //     <Button onClick={refetch}>Refresh Busroute data from server</Button>&nbsp;
        //     <Button onClick={resetAllMapReduxState}>Reset all Map data Redux state</Button><br/>
        //     {isLoading ?
        //         <span>Loading...</span>
        //         : data ? 
        //             <span>{JSON.stringify(data)}</span> 
        //             : <span>Error: {JSON.stringify(error)}</span>
        //     }
        // </>

        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: "20px",
                }}
            >
                <Card style={{ textAlign: "left", width: "600px" }}>
                    <Card.Header>Create New Student</Card.Header>
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">
                            <h1 className="h3 mb-3 fw-normal">{msg}</h1>
                        </Card.Subtitle>
                        <main className="form w-100 m-auto">
                            <div className='container'>
                                <form className='row'>
                                    <div className="col form-floating1 m-3">
                                        <label >Admission Number</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="NNNN format" onBlur={() => getStudent()}
                                            value={student.admissionNumber === 0 ? '' : student.admissionNumber} onChange={(e) => handleChange('admissionNumber', e.target.value)} />
                                    </div>
                                    <div className="col form-floating1 m-3">
                                        <label >Title</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Mas, Ms etc"
                                            value={student.title} onChange={(e) => handleChange('title', e.target.value)} />
                                    </div>
                                    <div className="form-floating1 m-3">
                                        <label >First Name *</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Enter First Name"
                                            value={student.firstName} onChange={(e) => handleChange('firstName', e.target.value)} />
                                    </div>
                                    <div className="form-floating1 m-3">
                                        <label >Middle Name</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Enter Middle Name"
                                            value={student.middleName} onChange={(e) => handleChange('middleName', e.target.value)} />
                                    </div>
                                    <div className="form-floating1 m-3">
                                        <label >Last Name *</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Enter Surname"
                                            value={student.lastName} onChange={(e) => handleChange('lastName', e.target.value)} />
                                    </div>

                                    <div className="col form-floating1 m-3">
                                        <label >Class *</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="1, 2, 3 ... (number)"
                                            value={student.className === 0 ? '': student.className} onChange={(e) => handleChange('className', e.target.value)} />
                                    </div>
                                    <div className="col form-floating1 m-3">
                                        <label >Section</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="A, B, C ..."
                                            value={student.section} onChange={(e) => handleChange('section', e.target.value)} />
                                    </div>
                                    <div className='row'>&nbsp;</div>
                                    <div className="col form-floating1 m-3">
                                        <label >Father Name</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Father's full name"
                                            value={student.fatherName} onChange={(e) => handleChange('fatherName', e.target.value)} />
                                    </div>
                                    <div className="col form-floating1 m-3">
                                        <label >Father Mobile Number</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="IMPORTANT!"
                                            value={student.fatherMobileNumber} onChange={(e) => handleChange('fatherMobileNumber', e.target.value)} />
                                    </div>
                                    <div className='row'></div>
                                    <div className="col form-floating1 m-3">
                                        <label >Mother Name</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Mother's full name"
                                            value={student.motherName} onChange={(e) => handleChange('motherName', e.target.value)} />
                                    </div>
                                    <div className="col form-floating1 m-3">
                                        <label>Mother Mobile Number</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder=""
                                            value={student.motherMobileNumber} onChange={(e) => handleChange('motherMobileNumber', e.target.value)} />
                                    </div>
                                    <div className="form-floating1 m-3">
                                        <label >Parent Email Address</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Parent's Personal email"
                                            value={student.primaryEmailAddress} onChange={(e) => handleChange('primaryEmailAddress', e.target.value)} />
                                    </div>
                                    <div className="col form-floating1 m-3">
                                        <label >Gender *</label>
                                        <Select placeholder='Select' value={student.gender} onChange={(e) => handleChange('gender', e.target.value)}>
                                            {getEnumEntries(StudentGenderEnum)}
                                        </Select>
                                    </div>
                                    {/* <div className="col form-floating1 m-3">
                                        <label >Transport *</label>
                                        <Select placeholder='Select' value={student.transportType} onChange={(e) => {
                                                window.alert('Change this value in Transport Services.');
                                                e.preventDefault();
                                            }}>
                                            {getEnumEntries(TransportTypeEnum)}
                                        </Select>
                                    </div> */}
                                    <div className="col form-floating1 m-3">
                                        <label >Date Of Birth</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="DD/MM/YYYY"
                                            value={student.dateOfBirth} onChange={(e) => handleChange('dateOfBirth', e.target.value)} />
                                    </div>
                                    
                                    <div className='row'></div>

                                    <div className="col form-floating1 m-3">
                                        <label >Join&nbsp;Year</label>
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Year of joining (YYYY)"
                                            value={student.joiningYear === 0 ? '' : student.joiningYear} onChange={(e) => handleChange('joiningYear', e.target.value)} />
                                    </div>

                                    <div className="col form-floating1 m-3">
                                        <label >Student Type</label>
                                        <Select>
                                            {getEnumEntries(StudentTypeEnum)}
                                        </Select>
                                    </div>


                                    <div className="col form-floating1 m-3">
                                        <label>Student Is Active?</label>
                                        <input type='checkbox' checked={student.isActive} onChange={(e) => handleChange('isActive', !student.isActive)}/>
                                    </div>

                                    <div className="m-auto">
                                        <button className="w-25 m-3 btn btn-md btn-primary" onClick={(e) => createStudent(e)} >{isUpdate ? 'Update' : 'Create'}</button>
                                        <button className="w-25 m-3 btn btn-md btn-danger" onClick={(e) => clearForm(e)}>Cancel</button>
                                    </div>
                                    <div><h1 className="h3 mb-3 fw-normal">{msg}</h1></div>
                                </form>
                            </div>
                        </main>
                    </Card.Body>
                    <Card.Footer>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                            }}
                        >
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </>
    )

}


export default Student;