

const Plceholder = ({text}: {text: string}) => {
    return (
        <>
            PlaceHolder: {text}
        </>
    )
}

export default Plceholder;