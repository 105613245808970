import {createApi} from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '../../../common/BaseQueryWithReAuth';
import { TAGS } from '../../../constants/app-constants';
import { IDCARDINFO_GET_URL, REGISTERIDCARDINFO_POST_URL } from '../../../constants/service-constants';
import { IIdCardInfo } from '../models/IIdCard';



export const idCardApi = createApi({
    reducerPath: 'idCardApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: [TAGS.IdCardInfo],
    endpoints: (builder) => ({
      getIdCardInfo: builder.query<IIdCardInfo[], string>({
        query: (cardNumber) => `${IDCARDINFO_GET_URL}${cardNumber}`,
      }),
      registerIdCard: builder.mutation<IIdCardInfo, Partial<IIdCardInfo>>({
        query: (cardInfo) => {
          return {
            url: `${REGISTERIDCARDINFO_POST_URL}`,
            method: 'POST',
            body: cardInfo
          }
        },
        invalidatesTags: [TAGS.IdCardInfo],
      })
    }),
  });
  
  export const { useLazyGetIdCardInfoQuery, useRegisterIdCardMutation } = idCardApi
  