import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '../../../common/BaseQueryWithReAuth';
import { TAGS } from '../../../constants/app-constants';
import { STAFF_CREATE_POST_URL, STAFF_DELETE_POST_URL, STAFF_INFO_ALL_GET_URL, STAFF_INFO_GET_URL } from '../../../constants/service-constants';

import { IStaffInfoRequest, IStaffInfoResponse } from '../models/IStaff';



export const staffApi = createApi({
  reducerPath: 'staffApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [TAGS.Staff],
  endpoints: (builder) => ({
    getStaff: builder.query<IStaffInfoResponse[], void>({
      query: () => `${STAFF_INFO_ALL_GET_URL}`,
      providesTags: [TAGS.Staff],
    }),

    getStaffByStaffId: builder.query<IStaffInfoResponse, number>({
      query: (staffId) => `${STAFF_INFO_GET_URL}${staffId}`,
      providesTags: [TAGS.Staff],
    }),
    
    createNewStaff: builder.mutation<IStaffInfoResponse, IStaffInfoRequest>({
      query({id,...rest}) {
        return {
          url: `${STAFF_CREATE_POST_URL}`,
          method: 'POST',
          body: rest
        }
      },
      invalidatesTags: [TAGS.Staff],
    }),

    deleteStaff: builder.mutation<boolean, string>({
      query(id) {
        return {
          url: `${STAFF_DELETE_POST_URL}${id}`,
          method: 'POST'
        }
      },
      invalidatesTags: [TAGS.Staff],
    }),
  }),

});

export const { useGetStaffQuery, useLazyGetStaffQuery, useLazyGetStaffByStaffIdQuery, useCreateNewStaffMutation, useDeleteStaffMutation } = staffApi
