import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '../../../common/BaseQueryWithReAuth';
import { ALLBUSROUTES_GET_URL, STUDENTS_IN_ROUTE_GET_URL } from '../../../constants/service-constants';
import { IBusRoute } from '../models/IBusRoute';
import { IStudentRoute } from '../models/IStudentRoute';
import { TAGS } from '../../../constants/app-constants';


export const busRoutesApi = createApi({
  reducerPath: 'busRoutesApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [TAGS.StudentBusRoutes],
  endpoints: (builder) => ({
    getBusRoutes: builder.query<IBusRoute[], void>({
      query: () => `${ALLBUSROUTES_GET_URL}`,
      transformResponse: (res: IBusRoute[]) => {
        return res.sort((b1, b2) => {
          if (b1.routeId < b2.routeId) return -1;
          if (b1.routeId > b2.routeId) return 1;

          if (b1.routeName < b2.routeName) return -1;
          if (b1.routeName > b2.routeName) return 1;

          return 0;
        });
      }
    }),

    getStudentsInRoute: builder.query<IStudentRoute[], string>({
      query: (busRouteId) => `${STUDENTS_IN_ROUTE_GET_URL}${busRouteId}`
    }),
  }),
});

export const { useGetBusRoutesQuery, useLazyGetStudentsInRouteQuery } = busRoutesApi
