import React, { useState } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { useGetBusRouteSelectionForStudentQuery, useUpdateStudentBusRouteMutation } from '../student/services/studentSlice';
import { ILandmark } from './models/ILandmark';
import { IDCARD_NUMBER_LENGTH, STUDENT_ADMISSION_NUMBER_LENGTH } from '../../constants/app-constants';
import BusRouteSelection from '../../components/BusRouteSelection/BusRouteSelection';
import { IBusRoute } from './models/IBusRoute';
import ToggleButton from '../../components/ToggleButton/ToggleButton';
import IdCardImage from '../../components/IdCard/IdCardImage';

const BusRoutes = () => {

  const [admissionNumber, setAdmissionNumber] = useState<string>('');
  const [idCardNumber, setIdCardNumber] = useState<string>('');
  const [id, setId] = useState<string>('');

  const [, setHasResults] = useState<boolean>(false);
  const [, setCurrentRouteResults] = useState<any>();
  const [selectedRouteId, setSelectedRouteId] = useState<string>('');
  const [selectedPickupLandmarkName, setSelectedPickupLandmarkName] = useState<string>('');
  const [selectedDropoffLandmarkName, setSelectedDropoffLandmarkName] = useState<string>('');
  const [saved, setSaved] = useState<boolean>(false);

  // const [isException, setIsException] = useState<boolean>(false);
  // const [isOwnTransportForToday, setIsOwnTransportForToday] = useState<boolean>();

  const { data: brsData, isError: brsIsError, isFetching: brsIsFetching } = useGetBusRouteSelectionForStudentQuery(id, { skip: id === '' });
  const [updateStudentBusRoute, { isLoading: savingRouteIsLoading }] = useUpdateStudentBusRouteMutation();

  const [isbusy] = useState<boolean>(brsIsFetching || savingRouteIsLoading);
  const [errMsg, setErrMsg] = useState<string>('');
  const [isSchoolTransport, setIsSchoolTransport] = useState<boolean>(true);


  const clearForm = () => {
    console.log('clearForm');
    setErrMsg('');
    setHasResults(false);
    setSaved(false);
    setAdmissionNumber('');
    setCurrentRouteResults('');
    setIdCardNumber('');
    setSelectedRouteId('');
    setSelectedPickupLandmarkName('');
    setSelectedDropoffLandmarkName('');
  }

  const keyUp = (e: { keyCode: number; }) => {
    if (e.keyCode === 13 && idCardNumber.length === IDCARD_NUMBER_LENGTH) {
      setId(idCardNumber);
    }
  }

  const routeSelected = (e: IBusRoute) => {
    setSelectedRouteId(e.routeId);
  }

  const pickupLandmarkSelected = (e: ILandmark) => {
    setSelectedPickupLandmarkName(e.landmarkName);
  }

  const dropoffLandmarkSelected = (e: ILandmark) => {
    setSelectedDropoffLandmarkName(e.landmarkName);
  }

  const idCardNumberChanged = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    console.log('idCardNumberChanged changed');
    setIdCardNumber(e.target.value);
    if (e.target.value.length === IDCARD_NUMBER_LENGTH) {
      //Idcard length is 10
      setId(e.target.value);
    }
  }

  const admissionNumberChanged = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    console.log('admissionNumber changed');
    setAdmissionNumber(e.target.value);
    if (e.target.value.length === STUDENT_ADMISSION_NUMBER_LENGTH) {
      //admissionNumber length is 4
      setId(e.target.value);
    }
  }


  const saveRecord = () => {
    updateStudentBusRoute({ 
      admissionNumber: Number(admissionNumber), 
      isOwnTransport: !isSchoolTransport, 
      routeId: selectedRouteId,
      pickupLandmarkName: selectedPickupLandmarkName,
      dropoffLandmarkName: selectedDropoffLandmarkName,
      routeName: '' 
    });
  }



  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '20px' }}>

        <Card style={{ textAlign: 'left', width: '450px' }}>
          <Card.Header>Display / Update Bus Route Selections</Card.Header>
          <Card.Body>
            <Card.Subtitle className="mb-2 text-muted">Click on the Id Card Number box and scan card once.</Card.Subtitle>

            <div style={{ height: '500px', textAlign: 'left', padding: '10px' }}>
              <div>
                <label className="form-label">ID Card Number (Scan Card):</label>
                <input className="form-control" type="text" value={idCardNumber} onKeyUp={keyUp} onChange={idCardNumberChanged}></input>
              </div>
              <span>OR</span>
              <div>
                <label className="form-label">Enter Admission Number:</label>
                <input className="form-control" type="text" value={admissionNumber} onChange={admissionNumberChanged}></input>
              </div>
              <hr />
              <ToggleButton trueText='School&nbsp;Transport' falseText='Own Transport' textWidth={155} onChange={setIsSchoolTransport} defaultChecked={true} disabled={false} />
              {isSchoolTransport ? 
              <BusRouteSelection showRouteSelectionText={true} showLandmarkSelection={true} routeSelected={routeSelected} 
                pickupLandmarkSelected={pickupLandmarkSelected}
                dropoffLandmarkSelected={dropoffLandmarkSelected}
                />
              : null}
            </div>
          </Card.Body>
          <Card.Footer>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>

              {isbusy && (
                <span>Processing Request...</span>
              )}

              <Button variant="primary" onClick={saveRecord}  >
                Update
              </Button>

              {saved && (
                <React.Fragment>
                  <Alert variant="success" style={{ width: '85%' }}>Selection Saved.</Alert>
                  <Button variant="primary" style={{ height: '30px' }} size="sm" onClick={clearForm}>Ok</Button>
                </React.Fragment>
              )}

              {errMsg && (
                <React.Fragment>
                  <Alert variant="danger" style={{ width: '85%' }}>{errMsg}</Alert>
                  <Button variant="danger" style={{ height: '30px' }} size="sm" onClick={clearForm}>Ok</Button>
                </React.Fragment>
              )}
            </div>
          </Card.Footer>
        </Card>
        <span>&nbsp;</span>
        <Card style={{ textAlign: 'left', width: '650px' }}>
          <Card.Header>ID Card</Card.Header>
          <Card.Body>
            <div>
              <div style={{ height: '500px', textAlign: 'center', padding: '10px' }}>
                <IdCardImage userIdNumber={`${admissionNumber}`}></IdCardImage>
                {admissionNumber && admissionNumber.length === STUDENT_ADMISSION_NUMBER_LENGTH && (
                  <>
                    <div style={{ paddingTop: '20px' }}>
                      <pre>
                        {brsIsFetching ? 'Fetching...' : !brsIsError && brsData && `${brsData.routeName}`}
                        <br />
                        {!brsIsFetching && !brsIsError && brsData && !brsData.isOwnTransport ? <>Pickup: {brsData.pickupLandmarkName} </> : null}
                        <br />
                        {!brsIsFetching && !brsIsError && brsData && !brsData.isOwnTransport ? <>Dropoff: {brsData.dropoffLandmarkName}</> : null}
                      </pre>
                    </div>
                  </>
                )}
              </div>


            </div>
          </Card.Body>

        </Card>

      </div>

    </>
  )
}


export default BusRoutes;

