import { useState } from "react";
import { IBusRoute } from "../../features/busRoutes/models/IBusRoute";
import { ILandmark } from "../../features/busRoutes/models/ILandmark";
import { useGetBusRoutesQuery } from "../../features/busRoutes/services/busRoutesSlice";

export interface IBusRouteSelectionProps {
  showRouteSelectionText?: boolean;
  showLandmarkSelection?: boolean;
  routeSelected: (route: IBusRoute) => void;
  pickupLandmarkSelected?: (landmark: ILandmark) => void;
  dropoffLandmarkSelected?: (landmark: ILandmark) => void;
}


const BusRouteSelection = ({ showRouteSelectionText, showLandmarkSelection, routeSelected, pickupLandmarkSelected, dropoffLandmarkSelected }: IBusRouteSelectionProps) => {
  const { data: brData, isError: brIsError } = useGetBusRoutesQuery();
  const [selectedRouteId, setSelectedRouteId] = useState<string>('');
  const [selectedPickupLandmarkName, setSelectedPickupLandmarkName] = useState<string>('');
  const [selectedDropoffLandmarkName, setSelectedDropoffLandmarkName] = useState<string>('');
  const [cascadeReady, setCascadeReady] = useState<boolean>(false);
  const [pickupLandmarks, setPickupLandmarks] = useState<ILandmark[]>();
  const [dropoffLandmarks, setDropoffLandmarks] = useState<ILandmark[]>();

  const routeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '')
      setCascadeReady(false);
    else
      setCascadeReady(true);

    var rtid = e.target.value;
    let rt = brData?.find((val: IBusRoute) => {
      return val.routeId === rtid;
    });
    if (rt) {
      if (rt.pickupLandmarks) {
        setPickupLandmarks(rt.pickupLandmarks);
      } else {
        setPickupLandmarks([]);
      }

      if (rt.dropoffLandmarks) {
        setDropoffLandmarks(rt.dropoffLandmarks);
      } else {
        setDropoffLandmarks([]);
      }
    }
    else {
      setPickupLandmarks([]);
      setDropoffLandmarks([]);
    }
    console.log(e.target.value);
    setSelectedRouteId(e.target.value);
    setSelectedPickupLandmarkName('');
    setSelectedDropoffLandmarkName('');
    if (rt) routeSelected(rt);
  }

  const pickupLandmarkChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let rt = brData?.find((val: IBusRoute) => {
      return val.routeId === selectedRouteId;
    });
    let plm = rt?.pickupLandmarks.find((val: ILandmark) => {
      return val.landmarkName === e.target.value;
    });
    setSelectedPickupLandmarkName(e.target.value);
    if (plm) pickupLandmarkSelected && pickupLandmarkSelected(plm);
  }

  const dropoffLandmarkChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let rt = brData?.find((val: IBusRoute) => {
      return val.routeId === selectedRouteId;
    });
    let dlm = rt?.dropoffLandmarks.find((val: ILandmark) => {
      return val.landmarkName === e.target.value;
    });
    setSelectedDropoffLandmarkName(e.target.value);
    if (dlm) dropoffLandmarkSelected && dropoffLandmarkSelected(dlm);
  }

  return (
    <>
      <div>
        {showRouteSelectionText ?
          <><span>Route Selection:</span><br /></>
        : null}   
        <select name="dropdown" id="routeDropdown" disabled={brIsError} value={selectedRouteId} onChange={e => routeChanged(e)}>
          <option value={''}>Choose a Route</option>
          {
            brData?.map((item, idx) => {
              return (
                <option key={idx} value={item.routeId}>{item.routeId} : {item.routeName}</option>
              )
            })
          }
        </select>
      </div>

      {showLandmarkSelection ?
        <>
        <div style={{ paddingTop: '20px' }}>
          <span>Pick Up Landmark:</span><br />
          <select name="dropdown" disabled={!cascadeReady || brIsError} id="pickupLandmarkDropdown" value={selectedPickupLandmarkName} onChange={e => pickupLandmarkChanged(e)}>
            <option value={''}>Choose a Landmark</option>
            {cascadeReady && pickupLandmarks?.map(item => {
              return (
                <option key={item.landmarkName} value={item.landmarkName}>{item.seqNumber} : {item.landmarkName}</option>
              )
            })
            }
          </select>
        </div>
        <div style={{ paddingTop: '20px' }}>
          <span>DropOff Landmark:</span><br />
          <select name="dropdown" disabled={!cascadeReady || brIsError} id="dropoffLandmarkDropdown" value={selectedDropoffLandmarkName} onChange={e => dropoffLandmarkChanged(e)}>
            <option value={''}>Choose a Landmark</option>
            {cascadeReady && dropoffLandmarks?.map(item => {
              return (
                <option key={item.landmarkName} value={item.landmarkName}>{item.seqNumber} : {item.landmarkName}</option>
              )
            })
            }
          </select>
        </div>
        </>
        : null}
    </>
  )
}

export default BusRouteSelection;