export enum IdCardType {
  Student = 'Student',
  Staff = 'Staff',
  TemporaryStudent = 'TemporaryStudent',
  TemporaryStaff = 'TemporaryStaff',
}

export interface IIdCardInfo {
  cardNumber: string,
  userNumber: string,
  idCardType: IdCardType,  
  isRecordDeleted: boolean,
  cardActivationDateTime: string,
  cardDeactivationDateTime?: string,
  entryDateTime: string,
}