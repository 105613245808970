import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '../../../common/BaseQueryWithReAuth';
import { TAGS } from '../../../constants/app-constants';
import { BUS_LOCATIONS_GET_URL, STUDENTSINBUS_GET_URL, STUDENT_LOCATIONS_GET_URL } from '../../../constants/service-constants';
import { IDeviceLocation } from '../models/IDeviceLocation';
import { IStudentLocation } from '../models/IStudentLocation';
import { IBusTrip } from '../models/IBusTrip';


export const mapDataApi = createApi({
  reducerPath: 'mapDataApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [TAGS.DeviceLocations, TAGS.StudentLocations, TAGS.StudentsInBus],
  endpoints: (builder) => ({
    getBusLocationByBusId: builder.query<IDeviceLocation[], { busId: string, startTs: number, endTs: number }>({
      query: ({ busId, startTs, endTs }) => `${BUS_LOCATIONS_GET_URL}?busId=${busId}&startTimestamp=${startTs}&endTimestamp=${endTs}`,
      providesTags: [TAGS.DeviceLocations],
    }),
    getStudentLocationByAdmissionNumber: builder.query<IStudentLocation[], { admNumber: number, startTs: number, endTs: number }>({
      query: ({ admNumber, startTs, endTs }) => `${STUDENT_LOCATIONS_GET_URL}?admissionNumber=${admNumber}&startTimestamp=${startTs}&endTimestamp=${endTs}`,
      providesTags: [TAGS.StudentLocations],
    }),
    getBusTripInfo: builder.query<IBusTrip, { busId: string, startTs: number, endTs: number }>({
      query: ({ busId, startTs, endTs }) => `${STUDENTSINBUS_GET_URL}?busId=${busId}&startTimestamp=${startTs}&endTimestamp=${endTs}`,
      providesTags: [TAGS.StudentsInBus],
    }),
  }),
});

export const { useLazyGetBusLocationByBusIdQuery, useLazyGetStudentLocationByAdmissionNumberQuery, useLazyGetBusTripInfoQuery } = mapDataApi
