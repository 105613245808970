import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useLazyGenerateStaffAttendanceReportQuery, useLazyGenerateStudentAttendanceReportQuery, useLazyGenerateStudentMonthlyAttendanceReportQuery } from './services/reportsSlice';


const AllReports = () => {
    // const { data, error, isLoading, refetch } = useGetBusRoutesQuery();
    const [genStudentReport, {isSuccess: genStudentSuccess, isLoading: isLoadingStudentReport}] = useLazyGenerateStudentAttendanceReportQuery();
    const [genStudentMonthlyReport, {isSuccess: genStudentMonthlySuccess, isLoading: isLoadingStudentMonthlyReport}] = useLazyGenerateStudentMonthlyAttendanceReportQuery();
    const [genStaffReport, {isSuccess: genStaffSuccess, isLoading: isLoadingStaffReport}] = useLazyGenerateStaffAttendanceReportQuery();
    const [msgStudent, setMsgStudent] = useState<string>('');
    const [msgStudentMonthly, setMsgStudentMonthly] = useState<string>('');
    const [msgStaff, setMsgStaff] = useState<string>('');

    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState<number>(currentYear);
    const previousMonth = new Date().getMonth();
    const [month, setMonth] = useState<number>(previousMonth < 0 ? 11 : previousMonth);

    useEffect(() => {
      if (genStudentSuccess) {
        setMsgStudent('Student Report emailed successfully to your @vrsvjschool.com email address');
      }
      if (genStudentMonthlySuccess) {
        setMsgStudentMonthly('Student Monthly Report emailed successfully to your @vrsvjschool.com email address');
      }
      if (genStaffSuccess) {
        setMsgStaff('Staff Report emailed successfully to your @vrsvjschool.com email address');
      }
    }, [genStudentSuccess, genStaffSuccess, genStudentMonthlySuccess]);

    const genStudentMonthlyReportWrapper= (year: number, month: number) => {
        if (year === new Date().getFullYear() && month === new Date().getMonth() + 1) { // getMonth() returns 0-11
            setMsgStudentMonthly('Please choose a month that is not the current month.');
            return;
        }
        genStudentMonthlyReport({year: year, month: month});
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: "20px",
                }}
            >
                <Card style={{ textAlign: "left", width: "100%" }}>
                    <Card.Header>Reports</Card.Header>
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">
                            <div>{msgStudent}&nbsp;</div>
                        </Card.Subtitle>
                            <Button onClick={() => {setMsgStudent(''); genStudentReport();}} disabled={isLoadingStudentReport}>
                                {isLoadingStudentReport ? 'Processing...' : 'Email Student Attendance and Transport Report'}
                            </Button>
                            <br/>
                            <br/>
                            <div>{msgStudentMonthly}&nbsp;</div>
                            <input type="number" value={year} onChange={(e) => {setMsgStudentMonthly(''); setYear(Number(e.target.value));}} style={{width: '75px'}} />
                            &nbsp;
                            <select name="month" value={month}  onChange={(e) => {setMsgStudentMonthly(''); setMonth(Number(e.target.value));}}>
                            {[...Array(12)].map((_, i) => (
                                <option key={i} value={i + 1}>
                                    {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                </option>
                            ))}
                            </select>
                            &nbsp;
                            <Button onClick={() => {setMsgStudentMonthly(''); genStudentMonthlyReportWrapper(year, month); }} disabled={isLoadingStudentMonthlyReport || year < 2024}>
                                {isLoadingStudentMonthlyReport ? 'Processing...' : 'Email Student Monthly Attendance Report'}
                            </Button>
                            <br/>
                            <br/>
                            <div>{msgStaff}&nbsp;</div>
                            <Button onClick={() => {setMsgStaff(''); genStaffReport(); }} disabled={isLoadingStaffReport}>
                                {isLoadingStaffReport ? 'Processing...' : 'Email Staff Attendance Report'}
                            </Button>
                            
                    </Card.Body>
                    <Card.Footer>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                            }}
                        >
                        </div>
                    </Card.Footer>
                </Card>

            </div>
        </>
    )

}


export default AllReports;