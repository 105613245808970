import { useEffect, useState } from "react";
import styles from './ToggleButton.module.scss';

export interface IToggleButtonProps {
  textWidth: number;
  trueText: string;  
  falseText: string;
  defaultChecked: boolean;
  onChange: (toggleState: boolean) => void;
  disabled: boolean;
}


// const CheckedIcon = () => <>🌜</>;
// const UncheckedIcon = () => <>🌞</>;

// https://github.com/webomnizz/react-toggle-button


const ToggleButton = ({ textWidth, trueText, falseText, defaultChecked, onChange, disabled }: IToggleButtonProps) => {

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (defaultChecked) {
      setToggle(defaultChecked)
    }
  }, [defaultChecked]);

  const triggerToggle = () => {
    if (disabled) {
      return;
    }

    setToggle(!toggle);

    if (typeof onChange === 'function') {
      onChange(!toggle);
    }
  }

  // const getIcon = (type) => {
  //   const { icons } = props;
  //   if (!icons) {
  //     return null;
  //   }
  //   return icons[type] === undefined ?
  //     ToggleButton.defaultProps.icons[type] :
  //     icons[type];
  // }

  // const toggleClasses = classNames('wrg-toggle', { 'wrg-toggle--checked': toggle, 'wrg-toggle--disabled': disabled }, className);


  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', justifyContent: 'flex-start' , paddingLeft: 2, paddingRight: 2}}>
      <div onClick={triggerToggle} className={`${styles.wrgToggle} ${toggle ? styles.wrgToggleChecked : ''} ${disabled ? styles.wrgToggleDisabled : ''}`}>
        <div className={styles.wrgToggleContainer} style={{width: `${textWidth}px`}}>
          <div className={styles.wrgToggleCheck}>
            {/* <span>{getIcon('checked')}</span> */}
            <span style={{width: `${1 - textWidth}px`}}>{trueText}</span>
          </div>
          <div className={styles.wrgToggleUncheck}>
            {/* <span>{getIcon('unchecked')}</span> */}
            <span style={{width: `${textWidth}px`, left: `-${textWidth - 50}px`}}>{falseText}</span>
          </div>
        </div>
        <div className={styles.wrgToggleCircle} style={{left: `${toggle ? (textWidth - 23) : 1}px`}}></div>
        <input type="checkbox" aria-label="App Toggle Button" className={styles.wrgToggleInput} />
      </div>
    </div>
  )
}

export default ToggleButton;