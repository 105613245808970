import { useEffect, useState } from "react";
import { getIdImage } from "../../common/BaseQueryWithReAuth";
import IdCardText from "./IdCardText";
import { STAFF_ID_NUMBER_LENGTH, STUDENT_ADMISSION_NUMBER_LENGTH } from "../../constants/app-constants";

export interface IIdCardProps {
  userIdNumber: string;
}

const IdCardImage = ({ userIdNumber }: IIdCardProps) => {

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [cardImage, setCardImage] = useState<string>('');
  const [isMissingStudentIdImage, setIsMissingStudentIdImage] = useState<boolean>(false);
    
  useEffect(() => {
    if (userIdNumber.length === STUDENT_ADMISSION_NUMBER_LENGTH || userIdNumber.length === STAFF_ID_NUMBER_LENGTH) {
      let objurl = '';
      setIsFetching(true);
      getIdImage(userIdNumber).then(res => {
        if (res !== undefined) {
          // let a = Buffer.from(res?.arrayBuffer).toString('base64')
          objurl =  URL.createObjectURL(res);
          setIsError(false);
          setCardImage(objurl);
          setIsMissingStudentIdImage(false);
        } else {
          setIsError(true);
          setCardImage('');
          setIsMissingStudentIdImage(true);
        }
        setIsFetching(false);
      });

      return () => URL.revokeObjectURL(objurl);   // do this to free up memory and prevent memory leaks
    }
  }, [userIdNumber]);

  const setbackupimage = (e: any) => {
    e.target.onerror = null;
    setIsMissingStudentIdImage(true);
  };


  return (
    <>
      {isFetching ? <>Fetching ID Card Image...<br/></> : null}
      {isError ? <>An error occurred fetching ID Card Image for {userIdNumber}!</>: null}

      {isMissingStudentIdImage ? 
        <>
          ID Card Image Not found!
          <br/>
          <IdCardText admissionNumber={Number(userIdNumber)}></IdCardText>
        </> :
        !isFetching && !isError && (
          <>
            <img height="400px" src={`${cardImage}`} alt="idCard" onError={setbackupimage}/>
          </>
        )
      }
    </>
  )
}

export default IdCardImage;